import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import MainRouter from './containers/MainRouter';
import store from './store';
import Localizer from './containers/Localizer';
import CapabilityHandler from './containers/CapabilityHandler';
import SessionValidator from 'Containers/SessionValidator';
import InvalidSessionView from 'Components/InvalidSessionView';
import { ConnectionManagerReactAdapter } from './net/ConnectionManagerReactAdapter';

// Create root should be outside of the render function
const container = document.getElementById('root');
const root = createRoot(container!);

// Render the main component, store and intl
const render = (Component: () => React.JSX.Element): void => {
  root.render(
    <ConnectionManagerReactAdapter>
      <Provider store={store}>
        <Localizer>
          <SessionValidator invalidated_state_view={<InvalidSessionView />}>
            <CapabilityHandler>
              <Component />
            </CapabilityHandler>
          </SessionValidator>
        </Localizer>
      </Provider>
    </ConnectionManagerReactAdapter>,
  );
};

render(MainRouter);

// Hot loading enabled
if (module.hot) {
  module.hot.accept('./containers/MainRouter', () => {
    const NewApp = require('./containers/MainRouter').default;
    render(NewApp);
  });
}
