import { styled, TooltipProps } from '@mui/material';
import { Container, Item } from 'Components/Grid';
import * as React from 'react';
import SyncStatus from './SyncStatus';
import { AlertsPopover } from './AlertsNotificationIcon';
import colors from '../../../config/theme/colors';
import { assertCapabilities, useAppDispatch, useAppSelector } from 'Store/index';
import { alerts } from '../../../config/generalSettings';
import { isPlatform } from 'Utility/typeGuards';
import { PlatformToArchiveMap } from '../../../config/platformSettings';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PlatformCapabilities from '../../../config/capabilities';
import { ICapabilityContextProps, withCapabilities } from 'Containers/CapabilityHandler';
import ToolTip from 'Components/ToolTip';
import { fm } from 'Components/FormatMessage';
import Icon from 'Components/_NewElements/Icon';
import { actions } from 'Store/session';

const StyledIconsArea = styled(Item, {
  shouldForwardProp: (prop) => prop !== 'bgColorAlternate',
})(({ bgColorAlternate }: { bgColorAlternate?: boolean }) => ({
  margin: '0 1rem',
  padding: '0.5rem 0.6rem',
  borderRadius: '5rem',
  backgroundColor: bgColorAlternate ? colors.appBlue.default : colors.headerIconAlternate,
  border: bgColorAlternate ? '0.1rem solid white' : 'none',
  display: 'flex',
  justifyContent: 'center',
}));
const StyledIconsContainer = styled(Container)({
  columnGap: '0.5rem',
});

const WithTooltip = ({
  text,
  children,
  tooltipPlacement,
}: {
  text: React.JSX.Element;
  children: React.JSX.Element;
  tooltipPlacement: SyncAlertIconArea['tooltipPlacement'];
}) => {
  return <ToolTip hover title={text} content={children} placement={tooltipPlacement} />;
};

const SyncAlertIconArea = ({
  iconSize = 2,
  bgColorAlternate,
  tooltipPlacement,
  capabilityGroups = {},
}: SyncAlertIconArea) => {
  const session = useAppSelector((state) => state.session);
  const documents = useAppSelector((state) => state.documents.sortedAndMergedDocuments) || [];
  const myAppDocs = useAppSelector((state) => state.myapp.sortedAndMergedDocuments) || [];
  const myAppId = useAppSelector((state) => state.myapp.mysqUserId);
  const delegates = useAppSelector((state) => state.patient.delegates) || [];

  const enabledAlerts =
    session?.platforms?.selected && Array.isArray(documents) && documents.length > 0
      ? alerts[session.platforms?.selected]?.filter((a) =>
          typeof a.rule === 'boolean' ? a.rule : a.rule(documents, myAppDocs),
        ) || []
      : [];

  const clinicalDialogCapability = assertCapabilities([PlatformCapabilities.CLINICAL_RECORDS], capabilityGroups);

  const currentPlatform = session?.platforms?.selected;
  const transferablePlatform = isPlatform(currentPlatform) ? PlatformToArchiveMap[currentPlatform].length > 0 : false;

  const dispatch = useAppDispatch();
  const censored = session.shouldCensor;
  const toggleCensor = (censor: string | null): void => {
    dispatch(actions.toggleCensor(censor));
  };

  const navigate = useNavigate();
  const location = useLocation();
  const openMessaging = () => {
    // Navigate to / if the search page is open to avoid having two messaging widgets/pages open at the same time
    if (location.pathname === '/search') navigate('/');
    dispatch(actions.setMessagesSessionSettings({ open: true }));
  };

  // Dev should be able to access message even if it has not been activated for patient
  const isDev = process.env.NODE_ENV === 'development';
  const hasMyService = myAppId || delegates.length > 0;

  return (
    <StyledIconsArea xs={3.5} md={'auto'} bgColorAlternate={bgColorAlternate}>
      <StyledIconsContainer alignItems={'center'} justifyContent={'center'} style={{ height: '100%' }}>
        <SyncStatus iconSize={iconSize} tooltipPlacement={tooltipPlacement} />
        {clinicalDialogCapability && transferablePlatform && (
          <WithTooltip text={fm('patientRecords.title')} tooltipPlacement={tooltipPlacement}>
            <Link to="/patientRecords" style={{ color: colors.primaryText }}>
              <Icon id="patientRecordsIcon" size={iconSize} icon="description" color="appBlueLight" button />
            </Link>
          </WithTooltip>
        )}
        <WithTooltip
          text={fm(censored ? 'header.showPatientInformation' : 'header.hidePatientInformation')}
          tooltipPlacement={tooltipPlacement}
        >
          <div
            id="informationCensorIcon"
            onClick={() => {
              toggleCensor(censored ? null : session.data?.patientid || null);
            }}
          >
            <Icon size={iconSize} icon={censored ? 'visibility' : 'visibilityOff'} color="appBlueLight" button />
          </div>
        </WithTooltip>
        <WithTooltip
          text={fm(isDev || hasMyService ? 'header.messaging' : 'messaging.patientNeedsMobileActivation')}
          tooltipPlacement={tooltipPlacement}
        >
          <div id="informationCensorIcon" onClick={isDev || hasMyService ? () => openMessaging() : undefined}>
            <Icon size={iconSize} icon={'mail'} color="appBlueLight" button />
          </div>
        </WithTooltip>
        <AlertsPopover
          iconSize={iconSize}
          alerts={enabledAlerts}
          alternateStyle={bgColorAlternate}
          tooltipPlacement={tooltipPlacement}
        />
      </StyledIconsContainer>
    </StyledIconsArea>
  );
};

interface SyncAlertIconArea extends ICapabilityContextProps {
  iconSize?: number;
  bgColorAlternate?: boolean;
  tooltipPlacement?: TooltipProps['placement'];
  clinicalDialogCapability?: boolean;
}

export default withCapabilities(SyncAlertIconArea);
