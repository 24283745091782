import { useState } from 'react';
import { isPartialDate, nowPartialDate } from 'neuro-utils';
import { useIntl } from 'react-intl';

import { IArchiveObject, IClinicalTextObject } from './utils/helpers';
import { ICompactVaultUser, INeuroArchiveTemplate } from 'neuro-data-structures';
import ClinicalDocElements from './utils/jsonToJSX';
import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import AuthorSelector, { ISelectedAuthors } from './components/AuthorSelector';
import DocumentHeader from 'Components/DocumentHeader';
import ActionButtonRounded from 'Components/ActionButtonRounded';
import { field } from '../../config/theme/componentTheme';
import { Container, Item } from 'Components/Grid';
import CenteredLoadingIndicator from 'Components/CenteredLoadingIndicator';
import InfoPopover from 'Components/InfoPopover';

const RecordPage = ({
  document,
  docTypeList,
  templateList,
  session,
  unsentTemplateIds,
  createArchive,
  deleteDraft,
  saveDraft,
  sendText,
  selectedAuthors,
  setSelectedAuthors,
  startDate,
  setStartDate,
  textContent,
  setTextContent,
  timeframe,
  setTimeframe,
  loading,
  onChangeSelectedNewDocumentTemplate,
}: IRecordPage) => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const [selectedTemplateForNewDocument, setSelectedTemplateForNewDocument] = useState<INeuroArchiveTemplate | null>(
    null,
  );
  const onChangeType = (values: TOnChangeValues) => {
    const templateId = values.type;
    const selectedTemplate = templateList.find((tl) => tl.id === templateId);
    if (selectedTemplate) {
      setSelectedTemplateForNewDocument(selectedTemplate);
      onChangeSelectedNewDocumentTemplate(selectedTemplate);
    }
  };

  const onChangeDate = (values: TOnChangeValues) => {
    const date = values.startDate;
    isPartialDate(date) && setStartDate(values.startDate as PartialDate);
  };

  const thisDocumentTemplate = templateList.find((tl) => tl.id === document?.templateId);

  // Sort templates by doctype and filter inactive templates
  const templatesByDocType = (docTypeList || []).flatMap((dtl) =>
    templateList.filter((tl) => tl.archiveType === dtl && tl.isActive),
  );

  const editing = !document;
  return (
    <>
      <DocumentHeader
        headerId={!document ? 'patientRecords.newDocument' : 'patientRecords.title'}
        editButtons={
          thisDocumentTemplate?.onCreateInfoText ? (
            <Container
              style={{ height: '100%', fontSize: '1.8rem', lineHeight: '2rem' }}
              alignSelf={'center'}
              alignItems={'center'}
            >
              <Item>
                <span style={{ marginRight: '1rem' }}>{fm('patientRecords.showHelp')}</span>
              </Item>
              <Item>
                <InfoPopover color="primary" text={<>{thisDocumentTemplate.onCreateInfoText}</>} />
              </Item>
            </Container>
          ) : undefined
        }
      />
      <FormRow title="patientRecords.type">
        <InputHandler
          type="Radio"
          name="type"
          options={templatesByDocType.map((tl) => tl.id)}
          optionFormatter={(option) =>
            `${templateList.find((tl) => tl.id === option)?.archiveType}${' - '}${templateList.find(
              (tl) => tl.id === option,
            )?.name}${
              unsentTemplateIds.includes(`${option}`) && editing ? ' - ' + fm('patientRecords.draftExists') : ''
            }`
          }
          editing={editing}
          formData={{
            document: { type: thisDocumentTemplate?.id || selectedTemplateForNewDocument?.id || null },
            onChange: onChangeType,
          }}
          disabledOptions={unsentTemplateIds}
        />
      </FormRow>
      <FormRow title="patientRecords.mainAuthor">
        <AuthorSelector
          selectedAuthors={selectedAuthors}
          setSelectedAuthors={setSelectedAuthors}
          users={session.users}
          currentUser={session.user}
          // Changing main author is not supported as of neuro-clinical-archive-service@0.10.3
          disabled={!editing}
        />
      </FormRow>
      <FormRow title="patientRecords.startDate">
        <InputHandler
          type="PartialDate"
          name="startDate"
          editing={true}
          formData={{ document: { startDate }, onChange: onChangeDate }}
          dateHook={{ dateHookCeiling: nowPartialDate() }}
        />
      </FormRow>
      {!document && !selectedTemplateForNewDocument ? (
        <>{fm('patientRecords.chooseTypeFirst')}</>
      ) : !document && selectedTemplateForNewDocument ? (
        <div style={{ whiteSpace: 'pre-line', margin: '3.5rem 0' }}>
          {selectedTemplateForNewDocument.onCreateInfoText}
        </div>
      ) : undefined}
      {!document && (
        <div style={{ marginTop: '2rem' }}>
          <FormRow>
            <ActionButtonRounded
              text="patientRecords.createDocument"
              width={20}
              height={4}
              fontSize={16}
              disabled={!selectedTemplateForNewDocument || !isPartialDate(startDate)}
              disabledTooltip={<>{fm('patientRecords.selectTypeAndDate')}</>}
              onClick={selectedTemplateForNewDocument ? () => createArchive(selectedTemplateForNewDocument) : undefined}
              alternate
              filled
              loading={loading.createNew}
            />
          </FormRow>
        </div>
      )}

      {document && thisDocumentTemplate && (
        <>
          <div
            style={{
              padding: '1.5rem',
              border: field.border,
              borderRadius: field.borderRadius,
              boxShadow: field.boxShadow,
            }}
          >
            {loading.changeDate === true ? (
              <CenteredLoadingIndicator />
            ) : (
              <ClinicalDocElements
                fm={fm}
                textContentNew={textContent}
                setTextContentNew={setTextContent}
                timeframeNew={timeframe}
                setTimeframeNew={setTimeframe}
                clinicalDocument={document}
                editing={true}
                template={thisDocumentTemplate}
              />
            )}
          </div>
          <Container style={{ marginTop: '2rem' }}>
            <Item xs={3}>
              <ActionButtonRounded
                text="patientRecords.deleteDraft"
                onClick={() => deleteDraft(document)}
                width={16}
                height={4}
                fontSize={16}
                colorScheme="error"
                loading={loading.deleteDocumentDraft}
              />
            </Item>
            <Item xs={true}>
              <Container justifyContent={'flex-end'}>
                <Item>
                  <ActionButtonRounded
                    text="patientRecords.saveDraft"
                    onClick={saveDraft}
                    width={16}
                    height={4}
                    fontSize={16}
                    loading={loading.savingTextChanges}
                  />
                </Item>
                <Item style={{ marginLeft: '2rem' }}>
                  <ActionButtonRounded
                    text="patientRecords.saveAndSend"
                    onClick={sendText}
                    width={37}
                    height={4}
                    fontSize={16}
                    alternate
                    filled
                    loading={loading.send}
                  />
                </Item>
              </Container>
            </Item>
          </Container>
        </>
      )}
    </>
  );
};

interface IRecordPage {
  document: IArchiveObject | null;
  docTypeList: string[] | null;
  templateList: INeuroArchiveTemplate[];
  session: {
    users: ICompactVaultUser[];
    user: ICompactVaultUser;
  };
  unsentTemplateIds: string[];
  createArchive: (template: INeuroArchiveTemplate) => void;
  deleteDraft: (document: IArchiveObject) => void;
  saveDraft: () => void;
  sendText?: () => void;
  // Author
  selectedAuthors: ISelectedAuthors;
  setSelectedAuthors: (authors: ISelectedAuthors) => void;
  // Start date
  startDate: PartialDate | null;
  setStartDate: (date: PartialDate) => void;
  // Text content
  textContent: IClinicalTextObject[];
  setTextContent: (textContent: IClinicalTextObject[]) => void;
  // Text content timeframe
  timeframe: IClinicalTextObject | undefined;
  setTimeframe: (timeframe: IClinicalTextObject | undefined) => void;
  // Loading info
  loading: {
    send?: boolean;
    changeDate?: boolean;
    createNew?: boolean;
    deleteDocumentDraft?: boolean;
    savingTextChanges?: boolean;
    templateList?: boolean;
  };
  // Changing template when creating new -callback
  onChangeSelectedNewDocumentTemplate: (template: INeuroArchiveTemplate) => void;
}

export default RecordPage;
