import { styled } from '@mui/system';

import { IArchiveObject } from '../utils/helpers';
import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import { Container, Item } from 'Components/Grid';
import { formatPartialDate, partialDateFromDate } from 'neuro-utils';
import { ICompactVaultUser, INeuroArchiveTemplate } from 'neuro-data-structures';
import ClinicalDocElements from '../utils/jsonToJSX';
import { useIntl } from 'react-intl';
import colors from '../../../config/theme/colors';

const TitleHeader = styled('div')({
  fontSize: '1.2rem',
  fontWeight: 400,
  lineHeight: '1.2rem',
});
const TitleValue = styled('div')({
  fontSize: '1.6rem',
  fontWeight: 600,
});

const timeStampToDateAndTime = (timestamp: number) => {
  const partialDate = partialDateFromDate(new Date(timestamp));

  const d = new Date(timestamp);
  const hours = ('0' + d.getHours()).slice(-2);
  const minutes = ('0' + d.getMinutes()).slice(-2);
  const dateAndTimeString = formatPartialDate(partialDate) + ' ' + hours + ':' + minutes;
  return dateAndTimeString;
};

const Title = ({
  document,
  template,
  users,
  fm,
}: {
  document: IDocumentHistoryItem['document'];
  template: IDocumentHistoryItem['template'];
  users: IDocumentHistoryItem['users'];
  fm: (id: string) => string;
}) => {
  const date = timeStampToDateAndTime(document.firstTimestamp || document.creationTimestamp);
  const type = (
    <>
      <span style={{ fontWeight: 600, textTransform: 'capitalize' }}>{document.archiveType}</span>
      <span style={{ fontWeight: 400 }}> / </span>
      <span style={{ fontWeight: 400 }}>{template.name}</span>
    </>
  );

  const getUserName = (id: string) => {
    const user = users.find((al) => al.userId === id);
    if (!user) return fm('patientRecords.unknownUser');
    return `${user.lastNames} ${user.firstNames}`;
  };

  const isDraft = !document.isSent;

  return (
    <Container alignItems={'center'}>
      <Item xs={4}>
        {date}
        {document.archiveRevision > 1 && (
          <span style={{ fontWeight: 400, fontSize: '1.4rem', marginLeft: '1rem' }}>
            {`${fm('patientRecords.version') + ' ' + document.archiveRevision}`}
          </span>
        )}
        <TitleValue style={{ fontWeight: 400, color: isDraft ? colors.error.default : undefined }}>
          {isDraft ? fm('patientRecords.draft') : fm('patientRecords.sent')}
        </TitleValue>
      </Item>
      <Item xs={4} md={3}>
        <TitleHeader>{fm('patientRecords.type')}</TitleHeader>
        <TitleValue>{type}</TitleValue>
      </Item>
      <Item xs={4} md={3}>
        <TitleHeader>{fm('patientRecords.mainAuthor')}</TitleHeader>
        <TitleValue>{getUserName(document.mainAuthor.userId)}</TitleValue>
      </Item>
    </Container>
  );
};

const DocumentHistoryItem = ({ ...props }: IDocumentHistoryItem) => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const isDraft = !props.document.isSent;
  return (
    <BlockWrapper
      title={<Title fm={fm} {...props} />}
      titleDivider
      collapse={{ open: props.index === 0 }}
      buttons={[
        {
          title: 'general.edit',
          onClick: props.updateDocument,
          props: {
            disabled: props.unsentExists && props.document.isSent,
            disabledTooltip: <>{fm('patientRecords.draftExists')}</>,
          },
        },
      ]}
      menuItems={[
        {
          title: <>{isDraft ? fm('patientRecords.deleteDraft') : fm('general.delete')}</>,
          onClick: isDraft ? props.deleteDraft : props.deleteDocument,
        },
      ]}
    >
      <ClinicalDocElements fm={fm} clinicalDocument={props.document} editing={false} template={props.template} />
    </BlockWrapper>
  );
};

interface IDocumentHistoryItem {
  index: number;
  document: IArchiveObject;
  template: INeuroArchiveTemplate;
  users: ICompactVaultUser[];
  updateDocument: () => void;
  deleteDocument: () => void;
  unsentExists: boolean;
  deleteDraft: () => void;
}

export default DocumentHistoryItem;
