import * as React from 'react';
import colors from '../../../config/theme/colors';

const returnFileSize = (sizeBytes: number) => {
  if (sizeBytes < 1e3) {
    return `${sizeBytes} bytes`;
  } else if (sizeBytes >= 1e3 && sizeBytes < 1e6) {
    return `${(sizeBytes / 1e3).toFixed(1)} KB`;
  } else {
    return `${(sizeBytes / 1e6).toFixed(1)} MB`;
  }
};

export const AttachmentForm = ({ fm, attachmentData, handleFileChange }: IAttachmentForm) => {
  return (
    <div style={{ display: 'grid', justifyItems: 'center', maxWidth: '40rem', overflow: 'hidden' }}>
      <div style={{ color: colors.primary, textAlign: 'center', maxWidth: '40rem' }}>
        <label
          htmlFor="image_upload"
          style={{ cursor: 'pointer', textDecoration: 'underline', height: '4rem', maxWidth: '40rem' }}
        >
          {fm(attachmentData && typeof attachmentData === 'object' ? 'messaging.file.change' : 'messaging.file.choose')}
        </label>
        <input
          id="image_upload"
          name="image_upload"
          type="file"
          onChange={handleFileChange}
          style={{ opacity: 0, height: 0 }}
          accept=".jpg, .jpeg, .png" // .pdf
        />
        <div style={{ fontSize: '1.4rem', color: colors.darkGray, marginTop: '-2rem' }}>
          {fm('messaging.file.allowedTypes')}: .jpg, .png
        </div>
      </div>
      {attachmentData && typeof attachmentData === 'object' && (
        <>
          <h3 style={{ lineHeight: '1.6rem' }}>{fm('messaging.file.chosen')}:</h3>
          <ul style={{ fontSize: '1.6rem', marginTop: '-1rem', maxWidth: 'inherit' }}>
            <li style={{ maxWidth: '25rem' }}>
              {fm('messaging.file.name')}: {attachmentData.name}
            </li>
            <li>
              {fm('messaging.file.type')}: {attachmentData.type}
            </li>
            <li>
              {fm('messaging.file.size')}: {returnFileSize(attachmentData.size)}
            </li>
          </ul>
        </>
      )}
    </div>
  );
};

interface IAttachmentForm {
  fm: (id: string) => string;
  attachmentData: File | boolean;
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
