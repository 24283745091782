import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Container, Item } from 'Components/Grid';
import InputHandler from 'Components/InputHandler';
import Dialog from 'Components/_NewElements/Dialog';

const ReasonDialog = ({ type, open = false, confirmCallback, cancelCallback }: IReasonDialog) => {
  const [reason, setReason] = useState<string | null>(null);

  useEffect(() => {
    if (!open && reason) setReason(null);
  }, [open]);

  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const baseReasons = ['errorInTyping', 'recordedInTheWrongCareEvent', 'changeBasedOnADecision', 'otherReason'];
  const deletionReasons = ['recordedToTheWrongPatient'];
  const newDraftReasons = ['becameMoreAccurate'];

  return (
    <Dialog
      open={open}
      title={fm(`patientRecords.${type === 'delete' ? 'deleteWholeDocument' : 'updateText'}`)}
      onClose={cancelCallback}
      dialogActions={[
        { text: 'general.cancel', onClick: cancelCallback },
        {
          text: 'general.accept',
          onClick: () => reason && confirmCallback(reason),
          colorScheme: 'error',
          filled: true,
          alternate: true,
          disabled: !reason,
        },
      ]}
      dialogProps={{ fullWidth: false }}
      dialogContentProps={{ style: { width: '60rem' } }}
    >
      <Container>
        {type === 'delete' && <div style={{ marginBottom: '2rem' }}>{fm('patientRecords.removeInfo')}</div>}
        <Item xs={4}>{fm(`patientRecords.${type === 'delete' ? 'deleteReason' : 'newDraftReason'}`)}</Item>
        <Item xs={8}>
          <InputHandler
            type="Select"
            editing={true}
            name="reason"
            formData={{
              onChange: (values: TOnChangeValues) => {
                if ('reason' in values && typeof values['reason'] === 'string') setReason(values['reason']);
              },
              document: { reason: reason },
            }}
            options={[...(type === 'delete' ? deletionReasons : newDraftReasons), ...baseReasons]}
            optionFormatter={(opt: string | number) => fm(`patientRecords.opts.${opt}`)}
            placeholder={type === 'delete' ? 'patientRecords.chooseDeleteReason' : 'patientRecords.newDraftReason'}
            width={30}
          />
        </Item>
      </Container>
    </Dialog>
  );
};

export interface IReasonDialog {
  type: 'delete' | 'newDraft';
  open: boolean;
  cancelCallback: () => void;
  confirmCallback: (reason: string) => void;
}

export default ReasonDialog;
