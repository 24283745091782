import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import RoundBox from 'Components/_NewElements/RoundBox';
import ConnectedPartialDate from 'Components/InputHandler/components/PartialDate';
import * as React from 'react';
import { FilterField, HeaderTimeDisplay, MessageLayout } from '../../../components/MessagingToPatient/components';
import { Container, Item } from 'Components/Grid';
import TextArea from 'Components/InputHandler/components/TextArea';
import ActionButtonRounded from 'Components/ActionButtonRounded';
import { jwtModify, patientSelect } from '../fetchers';
import { actions as sessionActions } from 'Store/session';
import { actions as messagingActions } from 'Store/messaging';
import { useAppDispatch, useAppSelector } from 'Store/index';
import ChatView from '../../../components/MessagingToPatient/components/ChatView';
import { IMessageListItem } from 'Store/messaging/interfaces';
import CircularProgressLocal from 'Components/CircularProgress';
import { messageFetchBuilder } from 'Store/messaging/fetchers/messages';
import { threadFetchBuilder } from 'Store/messaging/fetchers/threads';
import ConfirmationDialog from 'Components/ConfirmationDialog';
import Icon from 'Components/_NewElements/Icon';
import colors from '../../../config/theme/colors';
import ToolTip from 'Components/ToolTip';
import { AttachmentForm } from 'Components/MessagingToPatient/components/Attachments';
import { dateFromPartial } from 'neuro-utils';
import { styled } from '@mui/material';

const StyledFilterSubTitle = styled('div')(({ theme }) => ({
  fontSize: '1.2rem',
  color: theme.palette.grey[700],
  lineHeight: '1rem',
}));

const fetchNewJWT = (res: Response, dispatch: any): void => {
  if (res.status === 200) {
    res.text().then((text: string) => {
      jwtModify(text).then((success) => {
        if (success) {
          dispatch(sessionActions.loadSession());
        }
      });
    });
  }
};

const filterThreads = (thread: IThreadListItem, settings: Record<string, any>): boolean => {
  const filterFields = Object.keys(settings);
  return filterFields.every((field) => {
    switch (field) {
      case 'filterDateStart': {
        const filterStartPartial = settings['filterDateStart'] as PartialDate | undefined;
        const filterStartDate = filterStartPartial && dateFromPartial(filterStartPartial);

        // Return threads where latest message is within the filter date, creationTimestamp as backup
        const comparableTimestamp = thread.latestMessage.timestamp || thread.creationTimestamp;
        if (filterStartDate && filterStartDate.valueOf() > comparableTimestamp) return false;

        break;
      }
      default:
        break;
    }
    return true;
  });
};

const ThreadHeader = ({ thr, fm }: { thr: IThreadListItem; fm: IMessages['fm'] }) => {
  const lastDate = thr.latestMessage?.timestamp || null; // What should the date be when latestMessage does not exist?

  const users: TCompactVaultUser[] = useAppSelector((s) => s.session.orgUsers) || [];
  const thisHandler = (users || []).find((urs) => urs.userId === thr.assignedFor?.id);

  const completed = !!thr.completionTimestamp;

  return (
    <Container>
      <Item xs={9}>
        <div style={{ fontSize: '1.8rem' }}>{thr.topicTitle || fm('messaging.noTitle')}</div>
        {lastDate ? (
          <HeaderTimeDisplay date={lastDate} completed={completed} />
        ) : (
          <div style={{ fontSize: '1.4rem' }}>{fm('messaging.noDate')}</div>
        )}
      </Item>
      <Item xs={3} style={{ paddingRight: '1rem' }}>
        <div style={{ fontSize: '1.6rem', fontWeight: 600, display: 'flex', justifyContent: 'flex-end' }}>
          <Container>
            <Item xs={3} style={{ fontWeight: 400, fontSize: '1.4rem' }}>
              <div>{fm('messaging.patient')}</div>
              <div>{fm('messaging.handler')}</div>
            </Item>
            <Item xs={9} style={{ textAlign: 'end' }}>
              <div className="ellipsis">{thr.patientLastNames + ' ' + thr.patientFirstNames}</div>
              <div className="ellipsis">
                {!thr.assignedFor
                  ? '-'
                  : thisHandler
                    ? `${thisHandler.lastNames} ${thisHandler.firstNames}`
                    : fm('general.unknown')}
              </div>
            </Item>
          </Container>
        </div>
      </Item>
    </Container>
  );
};

const ThreadOpen = ({ thread, fm }: IThreadOpen) => {
  // const timedate = new Date(m.date);
  // const minutes = `${timedate.getMinutes().toString().length === 1 ? 0 : ''}${timedate.getMinutes()}`;

  const [messages, setMessages] = React.useState<IMessageListItem[]>([]);

  const dispatch = useAppDispatch();
  const updateMessages = () => {
    messageFetchBuilder<'list'>({ name: 'list', queryParams: [thread.patientId, thread.id] }).then((res) => {
      if (Array.isArray(res)) {
        setMessages(res);
      }
    });
  };
  React.useEffect(() => {
    updateMessages();
  }, []);

  const thisUserId: string = useAppSelector((s) => s.session.data?.useruuid) || '';
  const thisUserIsTheHandler = thread.assignedFor?.id === thisUserId;

  const [newMessageBody, setNewMessageBody] = React.useState<string | null>(null);

  const sendNewMessage = () => {
    newMessageBody &&
      messagingActions.newMessageToThread(newMessageBody, thread.patientId, thread.id).then(() => {
        updateMessages();
        setNewMessageBody(null);
      });
  };

  const assignUserToggle = async () => {
    if (thisUserIsTheHandler) {
      await threadFetchBuilder({ name: 'unassign', queryParams: [thread.id] });
    } else {
      await threadFetchBuilder({ name: 'assign', queryParams: [thread.id, thisUserId] });
    }
    dispatch(messagingActions.loadThreadList());
  };

  const completeDiscussionToggle = async () => {
    if (thread.completionTimestamp) {
      await threadFetchBuilder({ name: 'uncomplete', queryParams: [thread.id] });
    } else {
      await threadFetchBuilder({ name: 'complete', queryParams: [thread.id] });
    }
    dispatch(messagingActions.loadThreadList());
  };

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState<boolean>(false);
  const deleteDiscussion = async () => {
    setDeleteConfirmationOpen(true);
  };

  const [attachmentData, setAttachmentData] = React.useState<File | boolean>(false);
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setAttachmentData(e.target.files[0]);
    }
  };

  return (
    <>
      <MessageLayout
        buttons={[
          {
            text: (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {fm('messaging.openPatient')}
                <span style={{ padding: '0' }}>
                  <Icon icon={'rightMoveArrow'} />
                </span>
              </div>
            ),
            onClick: () => {
              patientSelect(thread.patientId).then((res) => res && fetchNewJWT(res, dispatch));
            },
            border: false,
          },
          {
            text: thisUserIsTheHandler ? 'messaging.unassignMeAsHandler' : 'messaging.assignMeAsHandler',
            onClick: assignUserToggle,
          },
          {
            text: thread.completionTimestamp ? 'messaging.markUncomplete' : 'messaging.markComplete',
            onClick: completeDiscussionToggle,
            colorScheme: 'warning',
          },
          {
            text: 'messaging.deleteDiscussion',
            onClick: () => deleteDiscussion(),
            colorScheme: 'error',
          },
        ]}
      >
        <>
          {messages.length > 0 && (
            <ChatView
              messages={messages}
              thread={thread}
              updateMessages={updateMessages}
              ended={thread.completionTimestamp}
            />
          )}
          {messages.length === 0 && <div></div>}
          {!thread.completionTimestamp && (
            <Container style={{ marginTop: '2rem' }}>
              {/* <Item style={{ fontWeight: 600, padding: '0 2rem' }}>{fm('messaging.reply')}</Item> */}
              <Item xs={true}>
                <TextArea
                  editing
                  type="TextArea"
                  name="reply"
                  placeholder={fm('messaging.replyPlaceholder')}
                  rows={2}
                  fullWidth
                  id="messagingReplyBox"
                  autoHeight
                  value={newMessageBody || ''}
                  onChange={(values) => {
                    const newMessageString = values['reply'];
                    if (typeof newMessageString === 'string') {
                      setNewMessageBody(newMessageString);
                    }
                  }}
                />
              </Item>
              <ToolTip
                hover
                title={fm('messaging.addAttachment')}
                content={
                  <Item
                    xs={'auto'}
                    style={{ padding: '0 1rem', cursor: 'pointer' }}
                    onClick={() => setAttachmentData(true)}
                  >
                    <Icon icon="attach" />
                  </Item>
                }
              />
              <Item xs={'auto'}>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <ActionButtonRounded
                    width="auto"
                    filled
                    height={3}
                    fontSize={15}
                    text={'messaging.send'}
                    disabled={!newMessageBody}
                    onClick={() => sendNewMessage()}
                  />
                </div>
              </Item>
            </Container>
          )}
        </>
      </MessageLayout>
      <ConfirmationDialog
        open={deleteConfirmationOpen}
        text={fm('messaging.deleteDiscussionConfirmation')}
        cancel={{
          callback: (e) => {
            e.stopPropagation();
            setDeleteConfirmationOpen(false);
          },
        }}
        confirm={{
          callback: async (e) => {
            e.stopPropagation();
            await threadFetchBuilder({ name: 'delete', queryParams: [thread.id] });
            dispatch(messagingActions.loadThreadList());
            setDeleteConfirmationOpen(false);
          },
        }}
      />
      <ConfirmationDialog
        open={!!attachmentData}
        fullWidth={false}
        text={<AttachmentForm fm={fm} attachmentData={attachmentData} handleFileChange={handleFileChange} />}
        cancel={{
          callback: (e) => {
            e.stopPropagation();
            setAttachmentData(false);
          },
        }}
        confirm={{
          disabled: !(attachmentData && typeof attachmentData === 'object'),
          text: 'messaging.send',
          callback: async (e) => {
            e.stopPropagation();
            // Send attachment
            if (attachmentData && typeof attachmentData === 'object')
              dispatch(
                messagingActions.postNewAttachment(attachmentData, thread.patientId, false, {
                  threadId: thread.id,
                  topicId: thread.topicId,
                }),
              );
            setTimeout(() => {
              updateMessages();
            }, 1000);
            setAttachmentData(false);
          },
        }}
      />
    </>
  );
};

interface IThreadOpen {
  thread: IThreadListItem;
  fm: IMessages['fm'];
}

const Messages = ({ fm }: IMessages) => {
  const [openThreadId, setOpenThreadId] = React.useState<string | null>(null);

  const [loadingThreads, setLoadingThreads] = React.useState<boolean>(true);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(messagingActions.loadThreadList()).then(() => {
      setTimeout(() => {
        setLoadingThreads(false);
      }, 500);
    });
  }, []);

  const threads = useAppSelector((s) => s.messaging.threads) || [];
  const [filterSettings, setFilterSettings] = React.useState<Record<string, any>>({});
  const activePatientThreads = threads
    .filter((thr) => filterThreads(thr, filterSettings))
    .filter((thr) => !thr.deletionTimestamp)
    .filter((thr) => !thr.completionTimestamp)
    .toSorted((a, b) => {
      const aDate = a.latestMessage?.timestamp || Date.now();
      const bDate = b.latestMessage?.timestamp || Date.now();
      return bDate - aDate;
    });

  const endedPatientThreads = threads
    .filter((thr) => filterThreads(thr, filterSettings))
    .filter((thr) => !thr.deletionTimestamp)
    .filter((thr) => thr.completionTimestamp)
    .toSorted((a, b) => {
      const aDate = a.latestMessage?.timestamp || Date.now();
      const bDate = b.latestMessage?.timestamp || Date.now();
      return bDate - aDate;
    });
  return (
    <div>
      <RoundBox style={{ padding: '1rem' }}>
        <FilterField title={fm('messaging.filterByDate')} type="row">
          <StyledFilterSubTitle>{fm('messaging.starting')}</StyledFilterSubTitle>
          <ConnectedPartialDate
            type="PartialDate"
            name="filterDateStart"
            editing
            onChange={(values) => {
              const date = values['filterDateStart'];
              setFilterSettings({ filterDateStart: date });
            }}
          />
        </FilterField>
      </RoundBox>
      <div style={{ marginTop: '2rem' }}>
        {loadingThreads ? (
          <Container justifyContent={'center'}>
            <Item>
              <CircularProgressLocal />
            </Item>
          </Container>
        ) : (
          <>
            {activePatientThreads.map((thr) => (
              <div key={thr.id} style={{ marginBottom: '1rem' }}>
                <BlockWrapper
                  collapse={{
                    open: openThreadId === thr.id,
                    collapseHandler: () => {
                      setOpenThreadId(openThreadId === thr.id ? null : thr.id);
                    },
                  }}
                  color={thr.completionTimestamp ? 'gray' : 'light'}
                  title={<ThreadHeader thr={thr} fm={fm} />}
                  contentMargin={2}
                  compact
                >
                  {openThreadId === thr.id && <ThreadOpen thread={thr} fm={fm} />}
                </BlockWrapper>
              </div>
            ))}
            {endedPatientThreads.length > 0 && (
              <div>
                <h3 style={{ color: colors.primary, margin: '2rem 0 1rem 0' }}>
                  {fm('messaging.completedDiscussions')}
                </h3>
                {endedPatientThreads.map((ethr) => (
                  <div key={ethr.id} style={{ marginBottom: '1rem' }}>
                    <BlockWrapper
                      collapse={{
                        open: openThreadId === ethr.id,
                        collapseHandler: () => {
                          setOpenThreadId(openThreadId === ethr.id ? null : ethr.id);
                        },
                      }}
                      color={ethr.completionTimestamp ? 'gray' : 'light'}
                      title={<ThreadHeader thr={ethr} fm={fm} />}
                      contentMargin={2}
                      compact
                    >
                      {openThreadId === ethr.id && <ThreadOpen thread={ethr} fm={fm} />}
                    </BlockWrapper>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

interface IMessages {
  fm: (id: string) => string;
}

export default Messages;
