import * as React from 'react';
import { FormattedMessage } from 'react-intl';

// Types of regimen and medications which use them
export const regimenTypesByMed = {
  default: ['STALEVO', 'DUODOPA'],
  static: ['SPINRAZA'],
} as { [key: string]: string[] };

// Match substances and dosage form to regimen type
export const regimenTypesBySubstanceAndDosageform = [
  { substance: /apomorfiini|foslevodopa/, dosageForm: /infuusioneste/, regimen: 'custom' },
  { substance: /levodopa/, dosageForm: /geeli suoleen/, regimen: 'custom' },
] as Array<{ substance: RegExp; dosageForm: RegExp; regimen: string }>;

// Which regimen types are shown in regimen selection by platform
export const regimenTypesByPlatform = {
  sma: ['default', 'onDemand', 'custom', 'single-dose'],
  dmd: ['default', 'onDemand', 'custom', 'single-dose'],
  parkinson: ['default', 'onDemand', 'custom', 'single-dose'],
  ms: ['default', 'onDemand', 'custom', 'single-dose'],
  other: ['default', 'onDemand', 'custom', 'single-dose'],
} as { [key: string]: string[] };

export const getAtcCodesByPlatform = (
  medicationSettings: IOrganizationNeuroSettings['medicationSettings'] | undefined,
  platform: string | undefined,
  filters?: { categories?: string[]; name?: string },
): string[] => {
  if (!medicationSettings || !platform) return [];

  const platformConfig = medicationSettings[platform as Platform];

  if (!platformConfig) {
    // If the platform is not found in the config, return an empty array
    return [];
  }

  // Initialize an empty set to store unique ATC codes
  const atcCodeSet = new Set<string>();

  platformConfig.medications.forEach((medication) => {
    // Apply filters
    const categoryMatch =
      filters?.categories && 'categories' in medication
        ? medication.categories?.some((c) => filters.categories?.includes(c))
        : true;
    const nameMatch = filters?.name && 'name' in medication ? medication.name === filters.name : true;

    if (categoryMatch && nameMatch) {
      // Add ATC codes to the Set
      medication.atcCodes.forEach((atcCode) => atcCodeSet.add(atcCode));
    }
  });

  return Array.from(atcCodeSet);
};

export const getThemeIdsAsAddonSettings = (
  medicationSettings: IOrganizationNeuroSettings['medicationSettings'] | undefined,
): { [key: string]: { theme: string } } => {
  if (!medicationSettings) return {};

  return Object.values(medicationSettings).reduce(
    (acc, platformSettings) => {
      if (platformSettings.themeIds) {
        for (const [atcCode, theme] of Object.entries(platformSettings.themeIds)) {
          acc[atcCode] = { theme };
        }
      }
      return acc;
    },
    {} as { [key: string]: { theme: string } },
  );
};

// Define the elements which are shown with static regimen, instead of regimen controls
export const staticRegimenElements = {
  SPINRAZA: (
    <div>
      <div style={{ fontWeight: 600 }}>
        <FormattedMessage id="medication.staticStart" />
      </div>
      <div>
        <FormattedMessage id="medication.spinraza.start" />
      </div>
      <div style={{ fontWeight: 600, marginTop: '2rem' }}>
        <FormattedMessage id="medication.staticUpkeep" />
      </div>
      <div>
        <FormattedMessage id="medication.spinraza.upkeep" />
      </div>
    </div>
  ),
} as { [key: string]: React.JSX.Element };

// Match substances to substance categories
export const medicationSubstancesByCategory = {
  levodopa: /levodopa/,
  maobInhibitors: /rasagiliini|selegiliini|safinamidi/,
  dopamineAgonists: /rotigotiini|apomorfiini|bromokriptiini|kabergoliini|pramipeksoli|ropiniroli/,
} as { [key: string]: RegExp };

export const pausationReasons: TPausationReason[] = [
  'condition',
  'otherMedication',
  'patientBasedOtherReason',
  'clinicallyIneffective',
  'adverseEffect',
  'treatment',
  'demobilize',
  'temporaryChange',
  'other',
];
