import { useState } from 'react';
import { PlatformSelectionList } from '../components/PlatformSelection';
import StatisticsModal from 'Components/StatisticsModal';

const StatisticsTab = ({ possibleStatisticsPlatforms }: IStatisticsTabs) => {
  const [selectedPlatform, setSelectedPlatform] = useState<Platform | null>(null);
  return (
    <>
      <PlatformSelectionList platforms={possibleStatisticsPlatforms} onChange={setSelectedPlatform} />
      <StatisticsModal
        modalOpen={!!selectedPlatform}
        getModalToggler={() => () => {
          setSelectedPlatform(null);
        }}
        platform={selectedPlatform || undefined}
      />
    </>
  );
};

interface IStatisticsTabs {
  possibleStatisticsPlatforms: Platform[];
}

export default StatisticsTab;
