import { Dialog, DialogActions, DialogContent } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { calculatePartialDateDiffenceDuration, formatPartialDate, formatTime, isPartialDate } from 'neuro-utils';

import InputHandler from '../../../../components/InputHandler';
import { Container, Item } from '../../../../components/Grid';
import FormRow from '../../../../components/FormRow';
import { actions as actionsAppointments } from '../../../../store/appointments';
import { useAppSelector as useSelector } from 'Store/index';
import { sortDocuments } from 'Utility/randomUtil';
import PlatformCapabilities from '../../../../config/capabilities';
import { assertCapabilities } from 'Store/index';
import { ICapabilityContextProps, withCapabilities } from 'Containers/CapabilityHandler';
import Columns from './Columns';
import ActionButtonRounded from 'Components/ActionButtonRounded';
import { HeaderComponent } from 'Components/Paper';
import { useAppDispatch } from 'Store/index';

const formatAppDate = (a?: IAppointment): React.JSX.Element => (
  <div>
    {a?.startDate ? (
      `${formatPartialDate(a.startDate)}${a.startTime ? ` (${formatTime(a.startTime)})` : ''}`
    ) : (
      <FormattedMessage id={'general.noDate'} />
    )}
  </div>
);

const Appointments = ({
  selectedAppointment,
  changeAppointment,
  capabilityGroups = {},
}: IAppointments): React.JSX.Element => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const handleDialogOpen = (open: boolean) => (): void => setDialogOpen(open);

  const [addNewData, setAddNewData] = React.useState<Partial<IAppointment>>({});

  const changeData = (values: TOnChangeValues): void => setAddNewData({ ...addNewData, ...values });

  const appointments = useSelector((s: IState) => s.appointments.appointments);

  const sortedAppointments = appointments
    ? [...appointments].sort((n1, n2) =>
        sortDocuments([
          { type: 'date', sortField: 'startDate' },
          { type: 'time', sortField: 'startTime' },
        ])(n1, n2),
      )
    : [];

  const saveAppointment = (): void => {
    actionsAppointments
      .createAppointment(addNewData as IAppointment)(dispatch)
      .then((id) => {
        if (id) {
          setAddNewData({});
          changeAppointment({ appointment: id });
          handleDialogOpen(false)();
        }
      })
      .catch(() => {
        return;
      });
  };

  // Return appointments from max 2 months ago, or max 3 days in the future
  const filteredAppointments = sortedAppointments?.filter((a) => {
    if (!a.startDate || !isPartialDate(a.startDate)) return false;
    const dayDif = calculatePartialDateDiffenceDuration('days', a.startDate).days;
    if (dayDif > 60 || dayDif < -3) return false;
    return true;
  });

  const dispatch = useAppDispatch();
  React.useEffect(() => {
    actionsAppointments.loadAppointments()(dispatch);
  }, [dispatch]);

  const appointmentsCreationCapability = assertCapabilities(
    [PlatformCapabilities.APPOINTMENTS_CREATE],
    capabilityGroups,
  );

  return (
    <>
      <Columns
        leftItem={
          <InputHandler
            type="Select"
            name="selectedAppointment"
            editing={true}
            options={filteredAppointments?.map((a) => a.id)}
            optionFormatter={(id): React.JSX.Element => (
              <>{formatAppDate(sortedAppointments?.find((a) => a.id === id))}</>
            )}
            formData={{
              onChange: changeAppointment,
              document: { selectedAppointment },
            }}
            placeholder={'appointments.appointment'}
          />
        }
        rightItem={
          appointmentsCreationCapability ? (
            <ActionButtonRounded
              width={12}
              height={3}
              fontSize={14}
              text="appointments.addNew"
              onClick={handleDialogOpen(true)}
            />
          ) : undefined
        }
      />

      <Dialog open={dialogOpen} maxWidth="sm" fullWidth={true} PaperProps={{ elevation: 2 }}>
        <HeaderComponent padding={3.5} header={<FormattedMessage id="appointments.addNewAppointment" />} />
        <DialogContent style={{ padding: '3.5rem' }}>
          <FormRow title="appointments.startDate">
            <InputHandler
              type="PartialDate"
              name="startDate"
              editing={true}
              formData={{
                onChange: changeData,
                document: { startDate: addNewData.startDate },
              }}
            />
          </FormRow>
          <FormRow title="appointments.startTime">
            <InputHandler
              type="TimePicker"
              name="startTime"
              editing={true}
              formData={{
                onChange: changeData,
                document: { startTime: addNewData.startTime },
              }}
            />
          </FormRow>
          <FormRow title="appointments.status">
            <InputHandler
              type="Select"
              name="status"
              editing={true}
              formData={{
                onChange: changeData,
                document: { status: addNewData.status },
              }}
              options={['booked', 'canceled', 'no-show', 'completed']}
              optionFormatter={(o): React.JSX.Element => <FormattedMessage id={`appointments.opts.${o}`} />}
              placeholder="appointments.statusPlaceholder"
            />
          </FormRow>

          <FormRow title="appointments.externaAppointmentType">
            <InputHandler
              type="TextField"
              name="externaAppointmentType"
              editing={true}
              formData={{
                onChange: changeData,
                document: { externaAppointmentType: addNewData.externaAppointmentType },
              }}
              placeholder="appointments.externaAppointmentTypePlaceholder"
            />
          </FormRow>

          <FormRow title="appointments.endDate">
            <InputHandler
              type="PartialDate"
              name="endDate"
              editing={true}
              formData={{
                onChange: changeData,
                document: { endDate: addNewData.endDate },
              }}
            />
          </FormRow>
          <FormRow title="appointments.endTime">
            <InputHandler
              type="TimePicker"
              name="endTime"
              editing={true}
              formData={{
                onChange: changeData,
                document: { endTime: addNewData.endTime },
              }}
            />
          </FormRow>
          <FormRow title="appointments.comments">
            <InputHandler
              type="TextArea"
              name="comments"
              editing={true}
              formData={{
                onChange: changeData,
                document: { comments: addNewData.comments },
              }}
              placeholder="appointments.commentsPlaceholder"
            />
          </FormRow>
        </DialogContent>
        <DialogActions style={{ padding: '0rem 3.5rem 3.5rem 3.5rem' }}>
          <Container alignItems="center">
            <Item xs={6}>
              <ActionButtonRounded
                text={'general.cancel'}
                onClick={handleDialogOpen(false)}
                width={15}
                height={4}
                fontSize={16}
              />
            </Item>
            <Item xs={6}>
              <Container justifyContent="flex-end" alignItems="center">
                <Item>
                  <ActionButtonRounded
                    text={'general.accept'}
                    onClick={saveAppointment}
                    width={15}
                    height={4}
                    fontSize={16}
                    filled
                  />
                </Item>
              </Container>
            </Item>
          </Container>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface IAppointments extends ICapabilityContextProps {
  selectedAppointment: string | null;
  changeAppointment: (vals: TOnChangeValues) => void;
}

export default withCapabilities(Appointments);
