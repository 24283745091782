import * as React from 'react';
import { connect } from 'react-redux';

import { actions } from '../../store/session';
import { RootState } from '../../store';
import { localStorageGet } from 'Utility/localStorage';
import CenteredLoadingIndicator from 'Components/CenteredLoadingIndicator';
import { FormattedMessage } from 'react-intl';
import MainLander from 'Routes/_MainLander';

type Props = IOwnProps & IStateFromProps & IDispatchFromProps;

class PlatformLoader extends React.Component<Props, IOwnState> {
  state = {
    platformsLoaded: false,
  };

  componentDidMount = () => {
    const {
      enabledPlatforms,
      visitingPatient,
      setPlatform,
      availablePlatforms,
      preferredPlatforms,
      preferredPlatformsAction,
    } = this.props;

    // Save all usable platforms from session data
    enabledPlatforms && availablePlatforms?.(enabledPlatforms);

    if (visitingPatient && !preferredPlatforms) {
      preferredPlatformsAction?.();
    }

    // Get currently selected platform from session storage, saved in visit reason screen
    const platform = localStorageGet('platform');
    if (platform && visitingPatient) {
      platform && setPlatform?.(platform);
    } else if (visitingPatient) {
      if (enabledPlatforms && enabledPlatforms.length > 0) {
        // If for some reason theres no selected platform in localStorage, pick the first available one
        setPlatform?.(enabledPlatforms[0]);
      } else {
        setPlatform?.('');
      }
    }
  };

  render(): React.JSX.Element | React.JSX.Element[] | null {
    const { isLoading, platforms, visitingPatient } = this.props;

    const loaded = isLoading === false; // undefined implies loading too
    const platformsExist = !!(platforms && platforms.length > 0);

    if (!visitingPatient) return <MainLander />;
    else if (loaded && visitingPatient && platformsExist) return this.props.children;
    else return <CenteredLoadingIndicator descriptionText={<FormattedMessage id="general.loadingPlatform" />} />;
  }
}

interface IStateFromProps {
  isLoading?: boolean;
  preferredPlatforms?: string[];
  platforms?: string[];
  enabledPlatforms?: string[];
  selected?: string;
  patientId?: string;
  visitingPatient?: boolean;
}

interface IDispatchFromProps {
  availablePlatforms?: (id: string[]) => void;
  preferredPlatformsAction?: () => void;
  setPlatform?: (id: string) => void;
  logOut?: () => void;
}

const mapDispatchToProps = (dispatch: any): any => ({
  availablePlatforms: (platforms: Platform[]): void => dispatch(actions.availablePlatforms(platforms)),
  preferredPlatformsAction: (): Promise<Platform[] | null> => actions.preferredPlatforms(dispatch),
  setPlatform: (platform: Platform): void => dispatch(actions.setPlatform(platform)),
  logOut: (): void => dispatch(actions.logOut()),
});

const mapStateToProps = (s: RootState): IStateFromProps => {
  return {
    isLoading: s.documents.isLoading,
    preferredPlatforms: s.session.platforms?.preferred, // Preferred platforms based on diagnoses and other factors
    platforms: s.session.platforms?.available, // All available platforms, Obsolete?
    enabledPlatforms: s.session.data?.platforms, // Platforms which have been enabled for this center
    selected: s.session.platforms?.selected,
    visitingPatient: !!(s.session?.data?.patientid && s.session?.data?.visitid),
  };
};

interface IOwnState {
  platformsLoaded: boolean;
}

interface IOwnProps {
  children: React.JSX.Element | React.JSX.Element[];
}

export default connect(mapStateToProps, mapDispatchToProps)(PlatformLoader);
