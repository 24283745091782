/**
 * Infobox which use Popper component to render the box (same as tooltip).
 * disableOnCloseProp property allows the box to be left open until manually closed.
 */

import { ClickAwayListener, IconButton, Paper, Popper } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import * as React from 'react';
import { styled } from '@mui/system';

import ActionButton from 'Components/ActionButton';

const StyledText = styled('div')(({ maxWidth }: { maxWidth?: number }) => ({
  fontSize: '1.6rem',
  padding: '1rem',
  maxWidth: `${maxWidth ? maxWidth : '40'}rem`,
  whiteSpace: 'pre-line',
  maxHeight: '65vh',
  overflowY: 'auto',
}));

const Button = styled(IconButton)`
  padding: 0 !important;
  margin: 0 !important;
`;

const InfoPopper = ({
  content,
  maxWidth,
  buttonColor = 'primary',
  buttonSize = 'normal',
  disableOnCloseProp = false,
}: IOwnProps): React.JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    setAnchorEl(!anchorEl ? event.currentTarget : null);
  };

  const handleClose = (event: MouseEvent | TouchEvent | React.MouseEvent): void => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'infopopper' : undefined;
  return (
    <React.Fragment>
      <ClickAwayListener onClickAway={disableOnCloseProp ? () => '' : handleClose}>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="auto-start"
          style={{ zIndex: 15 }}
          modifiers={[
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                padding: 10, // Padding from bounding element (document)
              },
            },
          ]}
        >
          <Paper elevation={3} style={{ margin: '1rem' }}>
            <StyledText maxWidth={maxWidth} onClick={handleClose}>
              {content}
            </StyledText>
            {disableOnCloseProp && (
              <div style={{ display: 'flex', padding: '1rem', justifyContent: 'center' }}>
                <ActionButton
                  text="general.close"
                  onClick={(e) => handleClose(e)}
                  filled
                  alternate
                  width={14}
                  height={4}
                  fontSize={16}
                />
              </div>
            )}
          </Paper>
        </Popper>
      </ClickAwayListener>
      <Button onClick={handleClick}>
        {!buttonColor ? (
          <div style={{ color: 'white' }}>
            <HelpIcon style={{ padding: 0, margin: 0, fontSize: buttonSize === 'large' ? '5rem' : '3rem' }} />
          </div>
        ) : (
          <HelpIcon
            color={buttonColor}
            style={{ padding: 0, margin: 0, fontSize: buttonSize === 'large' ? '5rem' : '3rem' }}
          />
        )}
      </Button>
    </React.Fragment>
  );
};

interface IOwnProps {
  content: React.JSX.Element;
  maxWidth?: number;
  buttonColor?: 'primary' | 'secondary';
  buttonSize?: 'normal' | 'large';
  disableOnCloseProp?: boolean;
}

export default InfoPopper;
