import { styled } from '@mui/system';

import colors from '../../../config/theme/colors';
import { useIntl } from 'react-intl';
import { Container, Item } from 'Components/Grid';
import InputHandler from 'Components/InputHandler';
import { StyledHeader } from '.';

const StyledTitle = styled('div')({
  color: colors.brandBlue.default,
  fontWeight: 600,
  fontSize: '1.6rem',
  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
});

export const PlatformSelectionList = ({ platforms, onChange }: IPlatformSelectionList) => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  return (
    <>
      <StyledHeader>{fm('extendedSearch.diseaseOrTreatmentPlaceholder')}</StyledHeader>
      {platforms.map((platform, index) => (
        <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '1rem' }}>
          <div style={{ display: 'flex', width: '1.5rem', paddingTop: '0.2rem' }}>
            <svg
              transform="rotate(-90)"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M5 5.71411L0.669872 0.267852L9.33013 0.267851L5 5.71411Z" fill={colors.brandBlue.default} />
            </svg>
          </div>
          <div style={{ display: 'flex', width: '100%' }}>
            <StyledTitle onClick={() => onChange(platform)}>{fm(`header.platforms.${platform}`)}</StyledTitle>
          </div>
        </div>
      ))}
    </>
  );
};

interface IPlatformSelectionList {
  platforms: Platform[];
  onChange: (platform: Platform) => void;
}

export const PlatformSelectionDropdown = ({ platforms, selectedPlatform, onChange }: IPlatformSelectionDropdown) => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const selectionOnChange = (values: TOnChangeValues) => {
    const platform = values['platform'] as Platform | undefined;
    platform && onChange(platform);
  };

  return (
    <Container style={{ borderBottom: `1px solid ${colors.gray}`, paddingBottom: '2rem', alignItems: 'center' }}>
      <Item xs={8} style={{ fontWeight: 600, color: colors.primary, fontSize: '1.8rem' }}>
        {fm('extendedSearch.diseaseOrTreatment')}
      </Item>
      <Item xs={4}>
        {platforms.length === 1 ? (
          fm(`header.platforms.${platforms[0]}`)
        ) : platforms.length > 1 ? (
          <InputHandler
            type="Select"
            editing={true}
            name="platform"
            formData={{
              onChange: selectionOnChange,
              document: { platform: selectedPlatform },
            }}
            options={platforms}
            optionFormatter={(name: string | number): string => fm(`header.platforms.${name}`)}
            placeholder="extendedSearch.diseaseOrTreatmentPlaceholder"
          />
        ) : null}
      </Item>
    </Container>
  );
};

interface IPlatformSelectionDropdown {
  platforms: Platform[];
  selectedPlatform?: Platform;
  onChange: (platform: Platform) => void;
}
