import ActionButtonRounded from 'Components/ActionButtonRounded';
import { Container, Item } from 'Components/Grid';
import InputHandler from 'Components/InputHandler';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'Store/index';
import { actions } from 'Store/session';

const NewMessage = ({ postNewDiscussion }: INewMessage) => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const dispatch = useAppDispatch();
  const newMessage = useAppSelector((s) => s.session.messagingSessionSettings.newMessage);

  const newMessageChange = (newMessage: Record<'title' | 'body', string> | undefined) => {
    dispatch(actions.setMessagesSessionSettings({ newMessage }));
  };
  return newMessage ? (
    <>
      <div style={{ marginBottom: '1rem' }}>
        <div>{fm('messaging.messageTitle')}</div>
        <div style={{ width: '30rem' }}>
          <InputHandler
            name="newMessageTitle"
            type="TextField"
            formData={{
              document: { newMessageTitle: newMessage['title'] },
              onChange: (values) => {
                newMessageChange({ ...newMessage, title: values['newMessageTitle'] as string });
              },
            }}
            editing
            placeholder={'messaging.messageTitle'}
            fullWidth
          />
        </div>
      </div>
      <div style={{ marginBottom: '2rem' }}>
        <div>{fm('messaging.messageBody')}</div>
        <div>
          <InputHandler
            name="newMessageBody"
            type="TextArea"
            formData={{
              document: { newMessageBody: newMessage['body'] },
              onChange: (values) => {
                newMessageChange({ ...newMessage, body: values['newMessageBody'] as string });
              },
            }}
            editing
            placeholder={'messaging.messageBody'}
            rows={4}
          />
        </div>
      </div>
      <Container>
        <Item xs={6}>
          <ActionButtonRounded
            text="messaging.deleteDraft"
            width={'auto'}
            height={3.5}
            fontSize={16}
            onClick={() => newMessageChange(undefined)}
            border={true}
          />
        </Item>
        <Item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ActionButtonRounded
            text="messaging.send"
            width="auto"
            height={3.5}
            fontSize={18}
            filled
            onClick={postNewDiscussion}
          />
        </Item>
      </Container>
    </>
  ) : undefined;
};

interface INewMessage {
  postNewDiscussion: () => void;
}

export default NewMessage;
