import { Container, Item } from 'Components/Grid';
import * as React from 'react';
import colors from '../../../config/theme/colors';
import { styled } from '@mui/system';
import { fm } from 'Components/FormatMessage';

const StyledControlsContainer = styled(Container)({
  marginTop: '-0.7rem',
  color: colors.primary,
  fontWeight: 600,
  fontSize: '1.6rem',
});

const StyledResultRow = styled(Container)({
  padding: '0.2rem 0 0.2rem 1rem',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: `${colors.primary} !important`,
    '& div': {
      color: `${colors.white} !important`,
    },
  },
});

const StyledRange = styled('div')({
  minWidth: '10rem',
  padding: '0 0.5rem 0 0.5rem',
  userSelect: 'none',
  textAlign: 'center',
});

const StyledControls = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disabled',
})(({ disabled }: { disabled?: boolean }) => ({
  userSelect: 'none' as const,
  color: `${disabled ? colors.gray : colors.primary} !important`,
  '&:hover': {
    cursor: disabled ? undefined : 'pointer',
  },
}));

const NextIcon = ({ skip, flip }: { disabled?: boolean; skip?: true; flip?: true }): React.JSX.Element => (
  <svg
    transform={flip ? 'rotate(180)' : undefined}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.29492 16.59L12.8749 12L8.29492 7.41L9.70492 6L15.7049 12L9.70492 18L8.29492 16.59Z"
      fill="currentColor"
    />
    {skip && <rect x="16" y="6" width="2" height="12" fill="currentColor" />}
  </svg>
);

const PageControls = ({
  searchResults,
  pages,
  pageNumber,
  setPageNumber,
  displayHeader = true,
}: IPageControls): React.JSX.Element => {
  const range = `${pageNumber * 25 + 1} - ${
    pageNumber * 25 + 25 > searchResults.length - 1 ? searchResults.length : pageNumber * 25 + 25
  } / ${searchResults.length}`;

  return (
    <StyledControlsContainer>
      <Item xs={6}>
        {displayHeader && (
          <>
            {fm('general.amountOfPatients')}
            {searchResults.length === 0 ? `: ${searchResults.length}` : undefined}
          </>
        )}
      </Item>
      {searchResults.length > 0 && (
        <Item xs={6} style={{ display: 'flex', justifyContent: 'right', position: 'relative', left: '0.5rem' }}>
          <StyledControls disabled={pageNumber === 0} onClick={() => (pageNumber === 0 ? '' : setPageNumber(0))}>
            <NextIcon skip flip />
          </StyledControls>
          <StyledControls
            disabled={pageNumber === 0}
            onClick={() => (pageNumber === 0 ? '' : setPageNumber(pageNumber - 1))}
          >
            <NextIcon flip />
          </StyledControls>
          <StyledRange>{range}</StyledRange>
          <StyledControls
            disabled={pageNumber === pages.length - 1}
            onClick={() => (pageNumber === pages.length - 1 ? '' : setPageNumber(pageNumber + 1))}
          >
            <NextIcon />
          </StyledControls>
          <StyledControls
            disabled={pageNumber === pages.length - 1}
            onClick={() => (pageNumber === pages.length - 1 ? '' : setPageNumber(pages.length - 1))}
          >
            <NextIcon skip />
          </StyledControls>
        </Item>
      )}
    </StyledControlsContainer>
  );
};

const SearchResults = ({ searchResults = [], onClickResult }: ISearchResults): React.JSX.Element => {
  const pages: Array<ISearchResults['searchResults']> = [];

  let page: ISearchResults['searchResults'] = [];
  searchResults
    .slice()
    .sort((a, b) => {
      // Compare last names
      const lastNameA = a.lastNames.toLowerCase();
      const lastNameB = b.lastNames.toLowerCase();
      if (lastNameA !== lastNameB) {
        return lastNameA < lastNameB ? -1 : 1;
      }
      // Compare first names if last names are the same
      const firstNameA = a.firstNames.toLowerCase();
      const firstNameB = b.firstNames.toLowerCase();
      return firstNameA < firstNameB ? -1 : firstNameA > firstNameB ? 1 : 0;
    })
    .forEach((r, i, arr) => {
      if (page.length < 25) {
        page.push(r);
      }
      if (page.length === 25 || i === arr.length - 1) {
        pages.push(page);
        if (i !== arr.length - 1) page = [];
        return;
      }
    });

  const [pageNumber, setPageNumber] = React.useState<number>(0);

  return (
    <div style={{ height: '100%' }}>
      <div style={{ marginBottom: '1rem' }}>
        <PageControls
          searchResults={searchResults}
          pages={pages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      </div>
      {(pages[pageNumber] || []).map((patient, index) => {
        const patientID = 'id' in patient ? patient.id : 'patientId' in patient ? patient.patientId : '';
        return (
          <StyledResultRow
            key={index}
            style={{
              backgroundColor: index % 2 === 0 ? colors.lightestGray : undefined,
            }}
            onClick={() => patient && onClickResult(patientID)}
          >
            <Item xs={6}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  userSelect: 'none',
                }}
              >
                <div style={{ display: 'flex', width: '1.5rem', color: colors.primary }}>
                  <svg
                    transform="rotate(-90)"
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M5 5.71411L0.669872 0.267852L9.33013 0.267851L5 5.71411Z" fill="currentColor" />
                  </svg>
                </div>
                <div
                  style={{ display: 'flex', paddingLeft: '0.5rem' }}
                >{`${patient.lastNames} ${patient.firstNames}`}</div>
              </div>
            </Item>
            <Item
              xs={6}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingRight: '2.5rem',
                userSelect: 'none',
              }}
            >
              {patient.ssn}
            </Item>
          </StyledResultRow>
        );
      })}
      {pages[pageNumber] && pages[pageNumber].length > 10 ? (
        <div style={{ marginTop: '2rem' }}>
          <PageControls
            searchResults={searchResults}
            pages={pages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            displayHeader={false}
          />
        </div>
      ) : null}
    </div>
  );
};

interface IPageControls {
  searchResults: ISearchResults['searchResults'];
  pages: Array<ISearchResults['searchResults']>;
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  displayHeader?: boolean;
}

interface ISearchResults {
  searchResults: Array<TNeuroPersonData | IExtendedSearchResults>;
  onClickResult: (id?: string) => void;
}

export default SearchResults;
