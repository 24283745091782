import * as React from 'react';
import colors from '../../../config/theme/colors';

import { field, historyValue } from '../../../config/theme/componentTheme';
import { styled } from '@mui/system';

const StyledDiv = styled('div')({
  width: '100%',
});

export const StyledTextArea = styled('textarea', {
  shouldForwardProp: (prop) => prop !== 'fullWidth' && prop !== 'maxHeight',
})<ITextAreaProps>(({ width, fullWidth, maxLength, maxHeight }: ITextAreaProps) => ({
  width: width ? `${width}rem` : fullWidth ? '100%' : '90%',
  maxlength: maxLength ? maxLength : undefined,
  borderRadius: field.borderRadius,
  fontSize: field.fontSize,
  fontWeight: field.fontWeight,
  textAlign: 'left',
  fontFamily: 'Titillium Web',
  letterSpacing: field.letterSpacing,
  boxShadow: field.boxShadow,
  border: field.border,
  padding: field.padding,
  boxSizing: 'border-box',
  maxHeight: maxHeight ? `${maxHeight}rem` : undefined,
}));

interface ITextAreaProps {
  width?: number | string;
  fullWidth?: boolean;
  maxLength?: number;
  maxHeight?: number;
}

/*
  React forgets the caret (or selection) position in TextArea elements when the value is controlled outside the component.
  React only handles positioning automatically if the onChange and setState are called within the same component.
  A workaround to this is to store the actual selection in each update and set it back to where it was after react has completed DOM manipulations
  using useLayoutEffect.
  Without this workaround, the caret will be moved to the end regardless of the position where the change was made. E.g. caret is moved to the end on each
  character entered in the middle of the text, making edits tedious to do without erasing the contents of the text area and rewriting it.
*/
const CaretControlledTextArea = ({
  name,
  id,
  value,
  onChange,
  placeholder,
  width,
  rows = 5,
  fullWidth,
  style,
  maxLength,
  autoHeight,
  maxHeight,
}: Partial<IInputBasics & ITextArea>): React.JSX.Element => {
  const [caretSelection, setCaretSelection] = React.useState<[number, number] | null>(null);
  const [shouldSaveValue, setShouldSaveValue] = React.useState<boolean>(false);

  const inputRef = React.useRef<HTMLTextAreaElement>(null);
  const valueRef = React.useRef<string | number | undefined>(value);

  React.useEffect(() => {
    if (shouldSaveValue && name) {
      onChange && onChange({ [name]: valueRef.current });
      setShouldSaveValue(false);
    }
  }, [shouldSaveValue]);

  React.useEffect(() => {
    if (name) {
      const submit = setTimeout(() => {
        setShouldSaveValue(true);
      }, 500);

      return () => clearTimeout(submit);
    }

    return;
  }, [valueRef.current]);

  React.useEffect(() => {
    if (value !== valueRef.current) {
      valueRef.current = value;
    }
  }, [value]);

  React.useLayoutEffect(() => {
    if (caretSelection && inputRef.current) {
      // This condition allows caret to stay in place when onChange is triggered after the timeout
      if (valueRef.current !== inputRef.current.value) {
        inputRef.current.selectionStart = caretSelection[0];
        inputRef.current.selectionEnd = caretSelection[1];
      }
    }
  }, [caretSelection, value]);

  const onChangeTextArea =
    (submit?: true) =>
    (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
      setCaretSelection([e.target.selectionStart, e.target.selectionEnd]);
      valueRef.current = e.currentTarget.value;
      submit && onChange && onChange({ [e.currentTarget.name]: e.currentTarget.value });
    };

  let textAreaElement: HTMLElement | null = null;
  const onInput = (thisElement: HTMLElement | null) => {
    if (!thisElement || !thisElement.style) return;
    thisElement.style.height = 'auto';
    thisElement.style.height = thisElement.scrollHeight + 'px';
  };

  React.useEffect(() => {
    if (id && autoHeight) {
      textAreaElement = document.getElementById(id);
      if (textAreaElement) {
        textAreaElement.setAttribute('style', 'height:' + textAreaElement.scrollHeight + 'px;overflow-y:hidden;');
        textAreaElement.addEventListener('input', () => onInput(textAreaElement), false);
      }
    }
    return () => {
      textAreaElement?.removeEventListener('input', () => onInput(textAreaElement));
    };
  }, []);

  return (
    <>
      <StyledTextArea
        ref={inputRef}
        id={id}
        name={name}
        rows={rows}
        placeholder={placeholder}
        onChange={onChangeTextArea()}
        onBlur={onChangeTextArea(true)}
        onKeyDown={(e) => e.key === 'Enter' && onChange && name && onChange({ [name]: inputRef.current?.value })}
        value={valueRef.current}
        width={width}
        fullWidth={fullWidth}
        style={{ color: style && style.color ? `${style.color}` : undefined }}
        maxLength={maxLength}
        maxHeight={maxHeight}
      />
      {maxLength && (
        <div
          style={{
            width: fullWidth ? '100%' : width ? `${width}rem` : '90%',
            textAlign: 'end',
            color: colors.tertiaryText,
          }}
        >{`${valueRef?.current?.toString().length ?? 0} / ${maxLength}`}</div>
      )}
    </>
  );
};

const TextArea = ({ editing = false, value, ...props }: IInputBasics & ITextArea): React.JSX.Element =>
  !editing ? (
    <StyledDiv
      style={{
        ...historyValue,
        whiteSpace: 'pre-line',
        color: props.style && props.style.color ? `${props.style.color}` : undefined,
      }}
    >
      {value || '-'}
    </StyledDiv>
  ) : (
    <CaretControlledTextArea value={value} editing={editing} {...props} />
  );

export default TextArea;
