import { DialogActions } from '@mui/material';
import * as React from 'react';

import { dialogActions } from '../../../config/theme/componentTheme';
import ActionButton from '../../ActionButton';

/** Controls for closing the stats modal. */
const Controls = (props: { closeBtnHandler: () => void }): React.JSX.Element => (
  <DialogActions style={dialogActions}>
    <ActionButton text="header.aboutDialog.close" width={10} height={3} fontSize={16} onClick={props.closeBtnHandler} />
  </DialogActions>
);

export { Controls };
