import * as React from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useIntl } from 'react-intl';
import { styled } from '@mui/system';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';

import { Container, Item } from '../Grid';
import colors from '../../config/theme/colors';
import { useAppDispatch } from 'Store/index';
import { actions } from 'Store/session';

const TopMarginArea = styled(({ ...other }) => <Item xs={12} {...other} />)({
  height: '8rem', // Header height and little extra
});

const AlertArea = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'width',
})<IStyleProps>(({ width }: IStyleProps) => ({
  maxWidth: `${width ? width : 60}rem`,
}));

const AlertText = styled('span')({
  fontWeight: 'bold',
  fontSize: '1.6rem',
});

const StyledMuiAlert = styled(MuiAlert)<IStyleProps>(({ severity = 'info', elevation = 1 }: IStyleProps) => ({
  pointerEvents: 'auto',
  color: colors[severity].dark,
  backgroundColor: colors[severity].lightest,
  border: `1px solid ${colors[severity].light}`,
  borderRadius: 0,
  boxShadow: elevation !== 0 ? '1px 1px 20px rgba(0, 0, 0, 0.25)' : undefined,
  alignItems: 'flex-start',
  '& .MuiAlert-icon': {
    padding: '3px 0',
  },
  '& .MuiAlert-icon svg': {
    color: colors[severity].default,
    fontSize: '3rem',
  },
  '& .MuiAlert-action': {
    padding: 0,
  },
  '& .MuiAlert-action svg': {
    color: colors[severity].light,
    fontSize: '3rem',
  },
}));

interface IStyleProps {
  width?: number;
  severity?: 'info' | 'warning' | 'error';
  elevation?: number;
}

export const RenderAlerts = ({
  alerts,
  topMargin,
  closeable,
  handleClose,
  width,
  elevation = 1,
}: IOwnProps & { handleClose?: (id: string) => void; elevation?: number }): React.JSX.Element => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });
  return (
    <AlertArea width={width}>
      {topMargin && <TopMarginArea />}
      {alerts.map((alert, i) => (
        <Item xs={12} style={{ marginBottom: '2rem' }} key={i}>
          <StyledMuiAlert
            onClose={closeable && handleClose ? () => handleClose(alert.id) : undefined}
            severity={alert.severity}
            elevation={elevation}
            variant="standard"
            iconMapping={{
              info: <InfoIcon />,
              warning: <WarningIcon />,
            }}
          >
            <Container alignItems="center" style={{ height: '100%' }}>
              <Item>
                {alert.customText ? (
                  <div style={{ fontSize: '1.6rem' }}>{alert.customText}</div>
                ) : (
                  <>
                    <AlertText>{`${fm(alert.textID).split(':')[0]}:`}</AlertText>
                    <AlertText style={{ fontWeight: 400 }}>{fm(alert.textID).split(':')[1]}</AlertText>
                  </>
                )}
              </Item>
            </Container>
            {alert.desc && typeof alert.desc === 'string' ? <Container>{alert.desc}</Container> : <>{alert.desc}</>}
          </StyledMuiAlert>
        </Item>
      ))}
    </AlertArea>
  );
};

const AlertsSnack = ({ alerts, topMargin = true, closeable = true, width }: IOwnProps): React.JSX.Element => {
  const dispatch = useAppDispatch();

  const handleClose = (id: string) => dispatch(actions.hideOrDeleteSnack(id));

  return (
    <Snackbar
      open={alerts.length > 0 ? true : false}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      style={{ pointerEvents: 'none' }}
    >
      <div>
        {/* Top element here needs to be div. Otherwise it will crash */}
        <RenderAlerts
          alerts={alerts}
          topMargin={topMargin}
          closeable={closeable}
          handleClose={handleClose}
          width={width}
        />
      </div>
    </Snackbar>
  );
};

interface ISnackAlert {
  id: string;
  textID: string;
  severity?: 'info' | 'warning' | 'error';
  customText?: string | React.JSX.Element;
  desc?: string | React.JSX.Element;
}

interface IOwnProps {
  alerts: Array<ISnackAlert>;
  autoHide?: boolean;
  autoHideDuration?: number; // ms
  topMargin?: boolean;
  closeable?: boolean;
  width?: number; // rem
}

export default AlertsSnack;
