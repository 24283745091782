import { fetchWithOptions } from 'Utility/fetch';
import { makeLog } from 'Utility/logger';
import { parseJWTFromCookie } from 'Utility/jwtAuthTools';
import { IDefaultFetcherInterface, queryParamsBuilder } from './util';
import { TATTACHMENT } from '../types/TAttachment';

interface IAttachmentCreate extends IDefaultFetcherInterface {
  name: 'create';
  body: File;
  /** patientid, topicid, threadid, messageid */
  queryParams: [string, string, string, string];
  contentType?: string;
}
interface IAttachmentDelete extends IDefaultFetcherInterface {
  name: 'delete';
  /** patientid, attachmentid */
  queryParams: [string, string];
}
interface IAttachmentFetch extends IDefaultFetcherInterface {
  name: 'fetch';
  /** patientid, attachmentid */
  queryParams: [string, string];
}
interface IAttachmentFetchThumb extends IDefaultFetcherInterface {
  name: 'fetchThumb';
  /** patientid, attachmentid, thumbnailId */
  queryParams: [string, string, string];
}

type TAttachmentActions = IAttachmentCreate | IAttachmentDelete | IAttachmentFetch | IAttachmentFetchThumb;

const basePath = '/api/messaging/v1';

const attachmentFetchConfig = {
  create: {
    method: 'POST',
    path: basePath + '/attachment/message',
  },
  delete: {
    method: 'DELETE',
    path: basePath + '/attachment',
  },
  fetch: {
    method: 'GET',
    path: basePath + '/attachment',
  },
  fetchThumb: {
    method: 'GET',
    path: basePath + '/thumbnail',
  },
};

type TDefaultReturn<N> = {
  returnType: N extends 'fetch' | 'fetchThumb' ? File : N extends 'create' ? TATTACHMENT | false : boolean;
};

export const attachmentFetchBuilder = <T>(
  builderProps: TAttachmentActions,
): Promise<TDefaultReturn<T>['returnType']> => {
  const actionName = builderProps.name;
  const path =
    attachmentFetchConfig[actionName].path +
    `${builderProps.queryParams ? queryParamsBuilder(builderProps.queryParams) : ''}`;

  const bodyFile = builderProps.body ? { body: builderProps.body as File } : {};
  const contentType = builderProps.name === 'create' && builderProps.contentType;

  if (builderProps.name === 'create' && !contentType) return new Promise(() => false);

  return fetchWithOptions(
    path,
    { neurojwt: parseJWTFromCookie(), 'Content-Type': contentType ? contentType : undefined },
    { method: attachmentFetchConfig[actionName].method, ...bodyFile },
  )
    .then((res) => {
      if (res.status === 200) {
        if (actionName === 'fetch' || actionName === 'fetchThumb') {
          return res.blob();
        }
        // Return response body parsed, or true if it cannot be parsed
        return res.json().catch(() => true);
      } else throw res;
    })
    .catch((res) => {
      makeLog(
        'Error',
        { name: `Attachment ${actionName} failed`, message: `${attachmentFetchConfig[actionName].method} failed` },
        res,
      );
      return null;
    });
};
