import { fetchWithOptions } from 'Utility/fetch';
import { makeLog } from 'Utility/logger';
import { parseJWTFromCookie } from 'Utility/jwtAuthTools';
import { TTOPIC } from '../types/TTopic';
import { IDefaultFetcherInterface, queryParamsBuilder } from './util';

export interface ITopicCreate extends IDefaultFetcherInterface {
  name: 'create';
  body: {
    title: string;
    targetPatients: string[];
  };
}
export interface ITopicDelete extends IDefaultFetcherInterface {
  name: 'delete';
}
export interface ITopicList extends IDefaultFetcherInterface {
  name: 'list';
}
export interface ITopicUpdate extends IDefaultFetcherInterface {
  name: 'update';
}
export interface ITopicFetch extends IDefaultFetcherInterface {
  name: 'fetch';
}
export interface ITopicActivate extends IDefaultFetcherInterface {
  name: 'activate';
  /** topicid */
  queryParams: [string];
}
export interface ITopicAssign extends IDefaultFetcherInterface {
  name: 'assign';
}
export interface ITopicUnassign extends IDefaultFetcherInterface {
  name: 'unassign';
}
export interface ITopicClose extends IDefaultFetcherInterface {
  name: 'close';
}

type TTopicActions =
  | ITopicCreate
  | ITopicDelete
  | ITopicList
  | ITopicUpdate
  | ITopicFetch
  | ITopicActivate
  | ITopicAssign
  | ITopicUnassign
  | ITopicClose;

const basePath = '/api/messaging/v1';

const topicFetchConfig = {
  create: {
    method: 'POST',
    path: basePath + '/topic',
  },
  delete: {
    method: 'DELETE',
    path: basePath + '/topic',
  },
  list: {
    method: 'GET',
    path: basePath + '/topics',
  },
  fetch: {
    method: 'GET',
    path: basePath + '/topic',
  },
  update: {
    method: 'PATCH',
    path: basePath + '/topic/content',
  },
  activate: {
    method: 'PATCH',
    path: basePath + '/topic/activate',
  },
  assign: {
    method: 'PATCH',
    path: basePath + '/topic/assign',
  },
  unassign: {
    method: 'PATCH',
    path: basePath + '/topic/unassign',
  },
  close: {
    method: 'PATCH',
    path: basePath + '/topic/close',
  },
};

type TDefaultReturn<N> = {
  returnType: N extends 'list' ? TTOPIC[] | false : N extends 'create' | 'update' | 'fetch' ? TTOPIC | false : boolean;
};

export const topicFetchBuilder = <T>(builderProps: TTopicActions): Promise<TDefaultReturn<T>['returnType']> => {
  const actionName = builderProps.name;
  const path =
    topicFetchConfig[actionName].path +
    `${builderProps.queryParams ? queryParamsBuilder(builderProps.queryParams) : ''}`;
  const bodyObject = builderProps.body ? { body: JSON.stringify(builderProps.body) } : {};

  return fetchWithOptions(
    path,
    { neurojwt: parseJWTFromCookie() },
    { method: topicFetchConfig[actionName].method, ...bodyObject },
  )
    .then((res) => {
      if (res.status === 200) {
        // Return response body parsed, or true if it cannot be parsed
        return res.json().catch(() => true);
      } else throw res;
    })
    .catch((res) => {
      makeLog(
        'Error',
        { name: `Topic ${actionName} failed`, message: `${topicFetchConfig[actionName].method} failed` },
        res,
      );
      return false;
    });
};
