import * as React from 'react';
import { connect } from 'react-redux';

import { actions } from '../../store/session';
import { actions as actionsAppointments } from '../../store/appointments';
import { actions as actionsSettings } from '../../store/settings';

import NotLoggedIn from './NotLoggedIn';

type Props = IOwnProps & IStateFromProps & IDispatchFromProps;

class SessionLoader extends React.Component<Props, IOwnState> {
  constructor(props: Props) {
    super(props);
    // Load user session
    this.props.loadSession?.();
    // Load appointments
    this.props.loadAppointments?.();

    this.props.loadOrganizationData?.();

    this.props.loadOrgSettings?.();
    this.props.loadUserSettings?.();
    this.props.loadSurveys?.();

    this.state = {
      timer: undefined,
      previousLogin: false,
    };
  }

  public check = (): void => {
    if (this.props.session?.data && this.props.session.data.exp < Date.now()) {
      this.props.checkSession?.();
    }
    if (this.props.session?.error) {
      this.setState({ timer: undefined });
    }
  };

  public componentDidMount = (): void => {
    if (this.props.session?.data) {
      this.setState({ previousLogin: true });
    }
    // Check session expired every 2 seconds
    this.setState({ timer: setInterval(this.check, 2000) });
  };
  public componentWillUnmount = (): void => {
    this.setState({ timer: undefined });
  };

  public render(): React.JSX.Element {
    if (this.props.session?.data && !this.props.session?.error) {
      return this.props.children(this.props.session, this.props.settings);
    }
    if (this.props.session?.error && this.state.previousLogin) {
      return (
        <NotLoggedIn
          error={this.props.session.error}
          reloginUri={this.props.session?.data?.idpUrl}
          loggedOut={this.props.session.loggedOut}
        />
      );
    }
    return <NotLoggedIn reloginUri={this.props.session?.data?.idpUrl} loggedOut={this.props.session?.loggedOut} />;
  }
}

interface IStateFromProps {
  session?: ISessionStore;
  settings?: ISettingsStore;
}

interface IDispatchFromProps {
  loadSession?: () => void;
  checkSession?: () => void;
  loadAppointments?: () => void;
  loadOrganizationData?: () => void;
  loadOrgSettings?: () => void;
  loadUserSettings?: () => void;
  loadSurveys?: () => void;
}

const mapStateToProps = (state: IState): IStateFromProps => ({
  session: state.session,
  settings: state.settings,
});

const mapDispatchToProps = (dispatch: any): any => ({
  loadSession: (): void => dispatch(actions.loadSession()),
  checkSession: (): void => dispatch(actions.checkSession()),
  loadAppointments: (): void => dispatch(actionsAppointments.loadAppointments()),
  loadOrganizationData: actions.loadOrganizationData(dispatch),
  loadOrgSettings: (): void => dispatch(actionsSettings.loadOrgSettings()),
  loadUserSettings: (): void => dispatch(actionsSettings.loadUserSettings()),
  loadSurveys: (): void => dispatch(actions.surveyRegisterAction()),
});

interface IOwnState {
  timer?: NodeJS.Timeout;
  previousLogin: boolean;
}

interface IOwnProps {
  children: (session: ISessionStore, settings?: ISettingsStore) => React.JSX.Element;
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionLoader);
