import * as React from 'react';
import { useAppSelector as useSelector } from 'Store/index';
import { Dialog, DialogContent } from '@mui/material';

import { Controls } from './components/Controls';
import PowerBIStatistics from './StatisticsComponent';

/** Modal showing statistics of the platform. */
const StatsModal = (props: IOwnProps): React.JSX.Element => {
  const { modalOpen: isOpen } = props;

  const modalClosingCb = props.getModalToggler(false);

  const currentlyOpenPlatform = useSelector((s: IState) => s.session.platforms?.selected);
  const platform = props.platform || currentlyOpenPlatform;

  return (
    <Dialog open={isOpen} maxWidth={'lg'} fullWidth scroll={'paper'} PaperProps={{ style: { minHeight: '90%' } }}>
      <DialogContent>{platform && <PowerBIStatistics platform={platform} />}</DialogContent>
      <Controls closeBtnHandler={modalClosingCb} />
    </Dialog>
  );
};

interface IOwnProps {
  modalOpen: boolean;
  getModalToggler: (s: boolean) => () => void;
  platform?: Platform;
}

export default StatsModal;
