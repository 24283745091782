import { ICompactVaultUser, INeuroArchiveTemplate } from 'neuro-data-structures';
import { useState, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';

import DocumentHeader from 'Components/DocumentHeader';
import DocumentWrapper from 'Components/DocumentWrapper';
import HistoryTabs from 'Components/HistoryTabs';
import Toolbar from 'Components/Toolbar';
import Dialog, { IThemedDialog } from 'Components/_NewElements/Dialog';
import ActionButtonRounded from 'Components/ActionButtonRounded';

import { ICapabilityContextProps, withCapabilities } from 'Containers/CapabilityHandler';
import NavigationBlocker from 'Containers/FormEditingHandler/NavigationBlocker';

import { useAppSelector } from 'Store/index';
import { assertCapabilities } from 'Store/index';
import PlatformCapabilities from '../../config/capabilities';

import DocumentHistoryItem from './components/DocumentHistoryItem';
import { ISelectedAuthors } from './components/AuthorSelector';
import ReasonDialog, { IReasonDialog } from './components/ReasonDialog';

import { IArchiveObject, IClinicalTextObject, getAvailableDocTypes } from './utils/helpers';
import {
  createDraftArchiveDocument,
  createNewArchiveRevision,
  deleteArchiveDocument,
  deleteArchiveDocumentDraft,
  getArchiveDocumentList,
  getTemplateList,
  refreshArchiveDocument,
  sendArchiveDocument,
  updateArchiveDocumentText,
} from './utils/fetchers';
import RecordPage from './RecordPage';
import { makeLog } from 'Utility/logger';

// Return timestamp as partialdate or null
const timeStampToStartDate = (timestamp: number | null): PartialDate | null => {
  if (timestamp === null) {
    return null;
  } else {
    const d = new Date(timestamp);
    return [d.getFullYear(), d.getMonth() + 1, d.getDate()];
  }
};

const PatientRecords = ({ capabilityGroups = {} }: IPatientRecords) => {
  const sessionData = useAppSelector((s: { session: ISessionStore }) => {
    /** Current user in session. */
    const user: ICompactVaultUser = {
      active: true,
      // user data should always be defined here but it's typed as possibly undefined...
      firstNames: s.session.data?.userfirstnames || '',
      lastNames: s.session.data?.userlastnames || '',
      userId: s.session.data?.useruuid || '',
    };
    return {
      org: s.session.data?.orgid || '',
      platform: s.session.platforms?.selected || '',
      patientId: s.session.data?.patientid || null,
      user,
    };
  });

  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const [creatingNew, setCreatingNew] = useState<boolean>(false);
  // All templates for currently selected platform and org
  const [templateList, setTemplateList] = useState<INeuroArchiveTemplate[] | null>(null);
  // All available document types for user/org/platform
  const [docTypeList, setDocTypeList] = useState<string[] | null>(null);
  // Selected clinical document template
  const [selectedDocType, setSelectedDocType] = useState<string | null>(null);
  // List of archive docs
  const [documentsList, setDocumentsList] = useState<IArchiveObject[]>([]);
  // Fetched/created clinical document which is shown to the user -- indicates that a draft is initiated
  const [clinicalDocument, setClinicalDocument] = useState<IArchiveObject | null>(null);
  // Document generation start date selection
  const [startDate, setStartDate] = useState<PartialDate | null>(null);
  // Author selection
  const [selectedAuthors, setSelectedAuthors] = useState<ISelectedAuthors>({
    main: sessionData.user.userId,
    secondary: null,
  });
  // Additional information added with text boxes
  const [textContentNew, setTextContentNew] = useState<IClinicalTextObject[]>([]);
  // Custom timeframe for specific text content block
  const [timeframeNew, setTimeframeNew] = useState<IClinicalTextObject | undefined>(undefined);

  // Loading indicator for when something async is going on
  const [loading, setLoading] = useState<{
    send?: boolean;
    changeDate?: boolean;
    createNew?: boolean;
    deleteDocumentDraft?: boolean;
    savingTextChanges?: boolean;
    templateList?: boolean;
  }>({});
  // Reason dialog settings
  const [reasonDialogSettings, setreasonDialogSettings] = useState<IReasonDialog | null>(null);
  // Informationdialog settings for different kinds of informations
  const [informationDialogSettings, setInformationDialogSettings] = useState<IThemedDialog | null>(null);
  // Confirmationdialog settings for different kinds of confirmations
  const [confirmationDialogSettings, setConfirmationDialogSettings] = useState<IThemedDialog | null>(null);

  const clearCurrentDocumentData = () => {
    setClinicalDocument(null);
    setStartDate(null);
    setTextContentNew([]);
    setSelectedAuthors({ main: sessionData.user.userId, secondary: null });
  };

  const clearRecordsSettings = () => {
    setCreatingNew(false);
    setLoading({});
    clearCurrentDocumentData();
    // Always refresh the documents when going back to the list view. This helps with keeping the textContentVersion field up to date
    refreshDocumentList();
  };

  const setCurrentDocumentData = (curDoc: IArchiveObject) => {
    setClinicalDocument(curDoc);
    setStartDate(timeStampToStartDate(curDoc.dataStartTimestamp));
    setSelectedAuthors({ main: curDoc.mainAuthor.userId, secondary: null });
  };

  // Function to refresh documents after any changes have occured
  const refreshDocumentList = (callbackThis?: () => void) => {
    getArchiveDocumentList(sessionData.org, sessionData.platform)
      .then((docs) => {
        if (Array.isArray(docs)) {
          setDocumentsList(docs);
        } else {
          setDocumentsList([]);
        }
        callbackThis?.();
      })
      .catch(() => {
        return;
      });
  };

  const refreshCurrentDocument = (
    newStartDate?: PartialDate,
    upTextContentVersion?: boolean,
    callbackThis?: () => void,
  ) => {
    const thisTemplate = templateList && templateList.find((tl) => tl.id === clinicalDocument?.templateId);
    if (clinicalDocument && thisTemplate) {
      refreshArchiveDocument(
        sessionData.org,
        sessionData.platform,
        thisTemplate.archiveType,
        clinicalDocument,
        newStartDate || clinicalDocument.dataStartTimestamp,
        null,
        upTextContentVersion,
      ).then((res) => {
        if (res) {
          setCurrentDocumentData(res);
          callbackThis?.();
        }
      });
    }
  };

  // Load list of possible templates for the org, module and doctype
  const loadTemplates = useCallback(
    (docType: string): Promise<INeuroArchiveTemplate[]> => {
      return getTemplateList(sessionData.org, sessionData.platform, docType)
        .then((templates) => {
          const fetchedTemplates = Array.isArray(templates) ? templates : [];
          return fetchedTemplates;
        })
        .catch(() => {
          return [];
        });
    },
    [sessionData.org, sessionData.platform],
  );

  /* Update available archive templates for platform when platform changes. */
  useEffect(() => {
    clearRecordsSettings();
    const docTypes = getAvailableDocTypes(capabilityGroups, sessionData.platform);
    setDocTypeList(docTypes);
    const templateFetches = docTypes.map((dt) => loadTemplates(dt));

    let templatesToState: INeuroArchiveTemplate[] = [];

    setLoading({ ...loading, templateList: true });
    Promise.all(templateFetches).then((tps) => {
      tps.forEach((templateList) => {
        if (templateList.length > 0) {
          templatesToState = templatesToState.concat(templateList);
        }
      });
      setTemplateList(templatesToState);
      setLoading({ ...loading, templateList: false });
      setSelectedDocType(docTypes[0] || null);
    });
  }, [sessionData.platform]);

  /** Save changes made to a specific text content block timeframe */
  useEffect(() => {
    if (timeframeNew) {
      saveTimeframeChanges();
    }
  }, [timeframeNew]);

  // Fetch updated/refreshed document for already sent documents, ready to be sent again
  const updateDocument = (id: string): void => {
    const thisDocument = documentsList.find((d) => d.id === id);
    const thisTemplate = templateList && templateList.find((tl) => tl.id === thisDocument?.templateId);
    const thisStartDate = thisDocument?.dataStartTimestamp;
    if (thisTemplate && thisDocument && thisStartDate) {
      if (!thisDocument.isSent) {
        refreshArchiveDocument(
          sessionData.org,
          sessionData.platform,
          thisTemplate.archiveType,
          thisDocument,
          thisDocument.dataStartTimestamp,
          thisDocument.dataEndTimestamp,
        ).then((res) => {
          res && setCurrentDocumentData(res);
        });
        return;
      }
      createNewArchiveRevision(sessionData.org, sessionData.platform, thisTemplate.archiveType, thisDocument)
        .then((amended) => {
          if (amended) {
            setCurrentDocumentData(amended);
            refreshDocumentList();
          }
        })
        .catch(() => {
          return;
        });
    }
  };

  const updateTimeframe = async (): Promise<boolean> => {
    if (clinicalDocument && timeframeNew) {
      return updateArchiveDocumentText(
        sessionData.org,
        sessionData.platform,
        clinicalDocument.archiveType,
        clinicalDocument,
        [timeframeNew],
      );
    } else {
      return false;
    }
  };

  const kantaDelay: number | undefined | null = useAppSelector((s: IState) => {
    const orgSettings = s.settings.orgSettings.settings;
    return orgSettings && typeof orgSettings === 'object' && 'kantaDelay' in orgSettings
      ? orgSettings.kantaDelay
      : null;
  });

  const saveTextChanges = async (): Promise<boolean> => {
    if (clinicalDocument && textContentNew.length > 0) {
      return updateArchiveDocumentText(
        sessionData.org,
        sessionData.platform,
        clinicalDocument.archiveType,
        clinicalDocument,
        textContentNew,
      );
    } else {
      return false;
    }
  };

  const saveTimeframeChanges = () => {
    setLoading({ ...loading, changeDate: true });
    updateTimeframe().then((res) => {
      if (res) {
        refreshCurrentDocument(undefined, true, () => {
          setTimeframeNew(undefined);
          setTimeout(() => setLoading({ ...loading, changeDate: false }), 200);
        });
      } else {
        openInformationDialog(fm('patientRecords.failInformation'));
      }
    });
  };

  const sendDocument = (reason?: string): void => {
    const thisTemplate = templateList?.find((tl) => tl.id === clinicalDocument?.templateId);
    if (clinicalDocument && thisTemplate && startDate) {
      const isAuthor = clinicalDocument.mainAuthor.userId === sessionData.user.userId;
      const isAmending = clinicalDocument.archiveRevision > 1;
      setLoading({ ...loading, send: true });
      saveTextChanges()
        .then((res: boolean) => {
          sendArchiveDocument(
            sessionData.org,
            sessionData.platform,
            thisTemplate.archiveType,
            {
              ...clinicalDocument,
              textContentVersion: res ? clinicalDocument.textContentVersion + 1 : clinicalDocument.textContentVersion, // Up the textcontentversion if the text was just updated
            },
            startDate,
            reason || null,
            kantaDelay ?? null,
          )
            .then((res) => {
              if (res !== 'sent' && res !== 'notSent') {
                // "Sending" the document has failed completely.
                openInformationDialog(fm('patientRecords.failInformation'));
              } else if (res === 'sent') {
                // Sending the document to our back end was successful
                // The document was also sent forward from our system
                const sendText =
                  (isAmending
                    ? isAuthor
                      ? thisTemplate.onAmendSendInfoTextForAuthor
                      : thisTemplate.onAmendSendInfoTextForOther
                    : isAuthor
                      ? thisTemplate.onSendInfoTextForAuthor
                      : thisTemplate.onSendInfoTextForOther) || fm('patientRecords.sendInformation');
                openInformationDialog(sendText, false);
                clearRecordsSettings();
              } else {
                // Sending the document to our back end was successful
                // The document was not sent forward to whatever HCP system
                openInformationDialog(fm('patientRecords.saveInformation'));
                clearRecordsSettings();
              }
            })
            .catch(() => {
              openInformationDialog(fm('patientRecords.failInformation'));
              setLoading({ ...loading, send: false });
            });
        })
        .catch(() => {
          return;
        });
    }
  };

  const deleteDocument = (documentId: string, reason: string) => {
    const thisDocument = documentsList.find((d) => d.id === documentId);
    const thisTemplate = templateList?.find((tl) => tl.id === thisDocument?.templateId);
    if (thisDocument && thisTemplate) {
      deleteArchiveDocument(sessionData.org, sessionData.platform, thisTemplate.archiveType, thisDocument, reason)
        .then((res) => {
          clearCurrentDocumentData();
          refreshDocumentList();
          if (res) openInformationDialog(fm('patientRecords.deleteInformation'));
          else openInformationDialog(fm('patientRecords.failInformation'));
        })
        .catch(() => {
          return;
        });
    }
  };

  const deleteDocumentDraft = (document: IArchiveObject): void => {
    const thisTemplate = templateList?.find((tl) => tl.id === document?.templateId);
    if (document && thisTemplate && !document.isSent) {
      setLoading({ ...loading, deleteDocumentDraft: true });
      deleteArchiveDocumentDraft(sessionData.org, sessionData.platform, thisTemplate.archiveType, document)
        .then((res) => {
          if (res) {
            clearRecordsSettings();
            setLoading({ ...loading, deleteDocumentDraft: false });
            setConfirmationDialogSettings(null);
            openInformationDialog(fm('patientRecords.draftDeleteInformation'));
          }
        })
        .catch((err) => {
          makeLog('Error', { name: 'Delete draft patient record', message: 'Failed to delete draft' }, err);
        });
    }
  };

  const createArchive = (template: INeuroArchiveTemplate) => {
    if (!selectedAuthors.main || !template || !startDate) return;
    setLoading({ ...loading, createNew: true });
    createDraftArchiveDocument(
      sessionData.org,
      sessionData.platform.toUpperCase(),
      template.archiveType,
      selectedAuthors.main,
      selectedAuthors.secondary,
      template.id,
      startDate,
    )
      .then((doc) => {
        if (doc) {
          setLoading({ ...loading, createNew: false });
          setCurrentDocumentData(doc);
          setSelectedDocType(doc.archiveType); // Automatically select tab corresponding to created document
          refreshDocumentList();
        }
      })
      .catch(() => {
        return;
      });
  };

  const openReasonDialog = (id: string, type: 'delete' | 'newDraft') => {
    setreasonDialogSettings({
      type,
      open: true,
      cancelCallback: () => {
        setreasonDialogSettings(null);
      },
      confirmCallback: (reason: string) => {
        setreasonDialogSettings(null);
        if (type === 'delete') deleteDocument(id, reason);
        if (type === 'newDraft') sendDocument(reason);
      },
    });
  };

  const openInformationDialog = (text: string, hasTimeout = true) => {
    setInformationDialogSettings({
      open: true,
      children: text,
      onClose: () => setInformationDialogSettings(null),
      closeTimeout: hasTimeout ? 1500 : undefined,
    });
  };

  const openDraftDeleteConfirmation = (document: IArchiveObject) => {
    setConfirmationDialogSettings({
      open: true,
      children: fm('patientRecords.deleteDraftConfirmationText'),
      onClose: () => {
        setConfirmationDialogSettings(null);
      },
      dialogActions: [
        {
          text: 'general.cancel',
          onClick: () => {
            setConfirmationDialogSettings(null);
          },
        },
        {
          text: 'general.delete',
          onClick: () => {
            deleteDocumentDraft(document);
          },
          colorScheme: 'error',
          filled: true,
          alternate: true,
        },
      ],
    });
  };

  const openSavingConfirmation = (document: IArchiveObject, callbackThis?: (canceled: boolean) => void) => {
    const thisTemplate = templateList?.find((tl) => tl.id === document?.templateId);
    if (thisTemplate)
      setConfirmationDialogSettings({
        open: true,
        children: fm('patientRecords.saveConfirmationText'),
        dialogActions: [
          {
            text: 'general.no',
            onClick: () => {
              setConfirmationDialogSettings(null);
              callbackThis?.(true);
            },
          },
          {
            text: 'general.yes',
            filled: true,
            alternate: true,
            onClick: () => {
              setLoading({ ...loading, savingTextChanges: true });
              saveTextChanges().then((res) => {
                if (res) {
                  refreshCurrentDocument(undefined, true, () => {
                    openInformationDialog(fm('patientRecords.saveInformation'), false);
                    setTextContentNew([]);
                    setLoading({ ...loading, savingTextChanges: false });
                    callbackThis?.(false);
                  });
                } else {
                  openInformationDialog(fm('patientRecords.failInformation'));
                }
              });
              setConfirmationDialogSettings(null);
            },
          },
        ],
      });
  };

  const onChangeStartDate = (newStartDate: PartialDate) => {
    if (clinicalDocument && !clinicalDocument.isSent) {
      setLoading({ ...loading, changeDate: true });
      // Refresh document when startDate changes
      refreshCurrentDocument(newStartDate, undefined, () => setLoading({ ...loading, changeDate: false }));
    } else {
      setStartDate(newStartDate);
    }
  };
  const onChangeSelectedNewDocumentTemplate = (template: INeuroArchiveTemplate) => {
    const previousDocument = documentsList.filter((d) => d.templateId === template.id)?.[0];
    // Set startDate with previous documents endDate if exists
    if (previousDocument?.dataEndTimestamp) setStartDate(timeStampToStartDate(previousDocument.dataEndTimestamp));
    else setStartDate(null);
  };

  const patientRecordsCapability = assertCapabilities([PlatformCapabilities.CLINICAL_RECORDS], capabilityGroups);

  const users = useAppSelector((state) => state.session.orgUsers || []);

  const documentsByType = documentsList.filter((d) => d.archiveType === selectedDocType);

  return patientRecordsCapability ? (
    <NavigationBlocker blocked={!!(clinicalDocument && textContentNew.length > 0 ? clinicalDocument : null)}>
      <>
        <Toolbar
          current="patientRecords"
          editing={clinicalDocument || creatingNew ? clinicalDocument?.archiveId || 'current' : undefined}
          cancelDraft={() => () => {
            if (textContentNew.length > 0) {
              clinicalDocument &&
                openSavingConfirmation(clinicalDocument, () => {
                  clearRecordsSettings();
                });
            } else {
              clearRecordsSettings();
            }
            setCreatingNew(false);
          }}
          hiddenElements={['saveButton', 'nextSection', 'dropDown']}
          customLocales={{ cancel: { title: 'general.close', toolTip: null } }}
          cancelButtonDisabled={!!(creatingNew && clinicalDocument)}
        />
        <DocumentWrapper>
          {!creatingNew && !clinicalDocument ? (
            <DocumentHeader
              headerId="patientRecords.title"
              editButtons={
                !clinicalDocument && !creatingNew
                  ? [
                      <ActionButtonRounded
                        width={26}
                        key="create"
                        text="patientRecords.newDocument"
                        onClick={() => setCreatingNew(true)}
                        disabled={!templateList || templateList.filter((tl) => tl.isActive).length === 0} // Disabled if no active templates
                        loading={loading?.templateList}
                        disabledTooltip={<>{fm('patientRecords.noTemplates')}</>}
                      />,
                    ]
                  : undefined
              }
            />
          ) : undefined}
          {creatingNew || clinicalDocument ? (
            <RecordPage
              document={clinicalDocument}
              docTypeList={docTypeList}
              templateList={templateList || []}
              session={{ users, user: sessionData.user }}
              unsentTemplateIds={documentsList.filter((doc) => !doc.isSent).map((notsent) => notsent.templateId)}
              createArchive={createArchive}
              deleteDraft={openDraftDeleteConfirmation}
              saveDraft={
                clinicalDocument
                  ? () => {
                      if (textContentNew.length > 0) {
                        setLoading({ ...loading, savingTextChanges: true });
                        saveTextChanges().then((res) => {
                          if (res) {
                            refreshCurrentDocument(undefined, true, () => {
                              openInformationDialog(fm('patientRecords.saveInformation'), false);
                              clearRecordsSettings();
                              setLoading({ ...loading, savingTextChanges: false });
                            });
                          } else {
                            openInformationDialog(fm('patientRecords.failInformation'));
                          }
                        });
                      } else {
                        clearRecordsSettings();
                      }
                    }
                  : () => ''
              }
              sendText={
                clinicalDocument
                  ? !clinicalDocument.archiveId
                    ? () => sendDocument()
                    : () => openReasonDialog(clinicalDocument?.id, 'newDraft')
                  : undefined
              }
              // Authors
              selectedAuthors={selectedAuthors}
              setSelectedAuthors={setSelectedAuthors}
              // StartDate
              startDate={startDate}
              setStartDate={onChangeStartDate}
              // Text content
              textContent={textContentNew}
              setTextContent={setTextContentNew}
              // Timeframe
              timeframe={timeframeNew}
              setTimeframe={setTimeframeNew}
              // Loading info
              loading={loading}
              onChangeSelectedNewDocumentTemplate={onChangeSelectedNewDocumentTemplate}
            />
          ) : (
            <HistoryTabs
              indexSelectionTools={{
                index: (docTypeList || []).findIndex((dt) => dt === selectedDocType),
                changeFunction: (index: number) => {
                  docTypeList && setSelectedDocType(docTypeList[index]);
                },
              }}
            >
              {(docTypeList || []).map((dt) => ({
                title: <>{dt}</>,
                count: documentsList.filter((dl) => dl.archiveType === dt).length,
                content: (
                  <>
                    {documentsByType.map((document, i) => {
                      const template = (templateList || []).find((tl) => tl.id === document.templateId);
                      return (
                        template && (
                          <DocumentHistoryItem
                            key={document.id || 'current'}
                            index={i}
                            document={document}
                            template={template}
                            users={users}
                            updateDocument={() => updateDocument(document.id)}
                            deleteDocument={() => {
                              openReasonDialog(document.id, 'delete');
                            }}
                            unsentExists={documentsByType.some((d) => !d.isSent)}
                            deleteDraft={() => openDraftDeleteConfirmation(document)}
                          />
                        )
                      );
                    })}
                  </>
                ),
              }))}
            </HistoryTabs>
          )}
        </DocumentWrapper>

        {informationDialogSettings && (
          /** Dialog with ok button or timeout */
          <Dialog
            open={informationDialogSettings.open}
            type="info"
            onClose={informationDialogSettings.onClose}
            closeTimeout={informationDialogSettings.closeTimeout}
          >
            {informationDialogSettings.children}
          </Dialog>
        )}

        {reasonDialogSettings && (
          /** Dialog with reason selection and cancel and accept buttons */
          <ReasonDialog
            type={reasonDialogSettings.type}
            open={reasonDialogSettings.open}
            cancelCallback={reasonDialogSettings.cancelCallback}
            confirmCallback={reasonDialogSettings.confirmCallback}
          />
        )}

        {/** Dialog with cancel and accept buttons */}
        <Dialog
          open={!!confirmationDialogSettings?.open}
          type="confirm"
          onClose={confirmationDialogSettings?.onClose}
          dialogActions={confirmationDialogSettings?.dialogActions}
          closeOnOutsideClick
        >
          {confirmationDialogSettings?.children}
        </Dialog>
      </>
    </NavigationBlocker>
  ) : undefined;
};

interface IPatientRecords extends ICapabilityContextProps {}

export default withCapabilities(PatientRecords);
