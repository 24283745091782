export const queryParamsBuilder = (queryParams: (string | undefined)[]) => {
  let paramsString = '';
  queryParams.map((param) => {
    if (param) paramsString += `${'/'}${param}`;
  });
  return paramsString;
};

export interface IDefaultFetcherInterface {
  body?: null | Record<string, any> | string;
  queryParams?: null | (string | undefined)[];
}
