import { styled } from '@mui/system';
import * as React from 'react';
import { useAppSelector, useAppSelector as useSelector } from 'Store/index';

import Censorable from '../../Censorable';
import { Container, Item } from '../../Grid';
import SyncAlertIconArea from './SyncAlertIconArea';
import { AlertsPopUps } from './AlertsNotificationIcon';
import { alerts } from '../../../config/generalSettings';

const NameDisplay = styled(Item)({
  maxWidth: '40rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  '@media only screen and (max-width: 1400px)': {
    maxWidth: '15rem',
  },
});

const PatientDetails = ({ patientData }: { patientData: IPatientStore['data'] }): React.JSX.Element => {
  const isLoading = useSelector((s: { documents: IDocumentStore }) => s.documents.isLoading);

  const nameString = patientData ? patientData.lastNames.toUpperCase() + ', ' + patientData.firstNames : null;

  return (
    <Item xs={true} style={{ marginRight: '2rem' }}>
      <Container>
        <Item
          style={{
            textAlign: 'center',
            color: isLoading === true && !patientData ? 'transparent' : undefined,
          }}
        >
          <h2 style={{ lineHeight: '2.6rem' /** So that the text does not clip */ }}>
            <Container>
              {nameString && (
                <NameDisplay>
                  <Censorable>{nameString}</Censorable>
                </NameDisplay>
              )}
              {patientData?.ssn && (
                <Item style={{ marginLeft: '1rem', fontSize: '1.6rem', fontWeight: 400 }}>
                  <Censorable>{patientData.ssn}</Censorable>
                </Item>
              )}
            </Container>
          </h2>
        </Item>
      </Container>
    </Item>
  );
};

export const PatientInfoAlerts = ({ patientData }: { patientData: IPatientStore['data'] }): React.JSX.Element => {
  const session = useAppSelector((state) => state.session);
  const documents = useAppSelector((state) => state.documents.sortedAndMergedDocuments) || [];
  const myAppDocs = useAppSelector((state) => state.myapp.sortedAndMergedDocuments) || [];

  const enabledAlerts =
    session?.platforms?.selected && Array.isArray(documents) && documents.length > 0
      ? alerts[session.platforms?.selected]?.filter((a) =>
          typeof a.rule === 'boolean' ? a.rule : a.rule(documents, myAppDocs),
        ) || []
      : [];
  return (
    <>
      <Container style={{ height: '100%' }} alignItems="center">
        <SyncAlertIconArea iconSize={2.4} />
        <PatientDetails patientData={patientData} />
        <AlertsPopUps alerts={enabledAlerts} />
      </Container>
    </>
  );
};
