import styled from '@mui/styled-engine';
import Icon from 'Components/_NewElements/Icon';
import ActionButtonRounded, { IActionButtonRounded } from 'Components/ActionButtonRounded';
import { Container, Item } from 'Components/Grid';
import { DateTime } from 'luxon';
import { formatPartialDate, partialDateFromDate } from 'neuro-utils';
import * as React from 'react';

const StyledFilterHeader = styled('div')({
  fontSize: '1.6rem',
  lineHeight: '1.8rem',
  fontWeight: '600',
  marginRight: '3rem',
  minWidth: '13rem',
});

export const FilterField = ({
  title,
  type,
  children,
}: {
  title: string;
  type: 'row' | 'col';
  children: React.JSX.Element | string | React.JSX.Element[];
}) => {
  return (
    <Container rowGap={'1.5rem'} alignItems={'center'}>
      <Item xs={type === 'row' ? 'auto' : 12}>
        <StyledFilterHeader style={{ textAlign: type === 'row' ? 'end' : 'start' }}>{title}</StyledFilterHeader>
      </Item>
      <Item xs={true}>{children}</Item>
    </Container>
  );
};

export const MessageLayout = ({ children, buttons }: IMessageLayout) => {
  const buttonsProps: Partial<IActionButtonRounded> = {
    width: 22,
    height: 3,
    fontSize: 15,
    uppercase: false,
  };
  return (
    <Container>
      <Item xs={7.5} md={8.5}>
        {/* Main message area */}
        {children}
      </Item>
      <Item xs={0.5} />
      <Item xs={true}>
        {/* Buttons area */}
        {buttons.map((but, i) => (
          <div key={i} style={{ marginBottom: '1rem' }}>
            <ActionButtonRounded key={i} {...buttonsProps} {...but} />
          </div>
        ))}
      </Item>
    </Container>
  );
};

interface IMessageLayout {
  children: React.JSX.Element;
  buttons: IActionButtonRounded[];
}

export const MessageDetailListItem = styled(Container)(({ theme }) => ({
  paddingLeft: '1rem',
  paddingTop: '0.5rem',
  borderTop: `0.1rem solid ${theme.palette.grey[500]}`,

  '* > &:not(:last-of-type)': {
    paddingBottom: '0.5rem',
  },
}));

export const MessageDetailListTitle = styled(Item)(({ theme }) => ({
  color: theme.palette.grey[700],
}));

export const MessageDetailRow = ({
  title,
  value,
  titleWidth = 4,
  fullWidthValue,
}: {
  title?: string | React.JSX.Element;
  value?: string | React.JSX.Element | null;
  titleWidth?: number;
  fullWidthValue?: boolean;
}) => (
  <MessageDetailListItem>
    {title && <MessageDetailListTitle xs={titleWidth}>{title}</MessageDetailListTitle>}
    <Item style={{ whiteSpace: 'pre-line', fontWeight: 600 }} xs={fullWidthValue ? 12 : true}>
      {value ?? '-'}
    </Item>
  </MessageDetailListItem>
);

export const HeaderTimeDisplay = ({ date, completed }: { date: number; completed?: boolean }) => {
  const jsDate = new Date(date);
  const minutes = `${jsDate.getMinutes().toString().length === 1 ? 0 : ''}${jsDate.getMinutes()}`;

  const dateTime = DateTime.fromJSDate(jsDate);

  return (
    <div style={{ fontSize: '1.4rem', fontWeight: 600, marginTop: '0', display: 'flex', alignItems: 'center' }}>
      <Icon icon={'event'} size={1.8} color={completed ? 'grayDark' : undefined} />
      <div style={{ marginLeft: '0.5rem', marginRight: '2rem' }}>{formatPartialDate(partialDateFromDate(jsDate))}</div>
      <Icon icon={'time'} size={1.8} color={completed ? 'grayDark' : undefined} />
      <div style={{ marginLeft: '0.5rem' }}>{`${jsDate.getHours()}:${minutes}`}</div>
      <div style={{ marginLeft: '2rem', fontWeight: 400 }}>{`(${dateTime.toRelative()})`}</div>
    </div>
  );
};
