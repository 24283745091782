import * as React from 'react';
import { styled } from '@mui/system';
import colors from '../../config/theme/colors';
import { Container, Item } from '../Grid';
import ClearIcon from '@mui/icons-material/Clear';

const ClearIconStyled = styled(ClearIcon)({
  display: 'block',
  width: '3rem',
  color: colors.secondaryText,
  cursor: 'pointer',
  '&:hover': {
    color: 'red',
  },
});

const StyledNewRowButton = styled('div')({
  fontSize: '1.5rem',
  '&:hover': {
    cursor: 'pointer',
    color: colors.primary,
  },
});

/**
 * Component for viewing an array of elements and a button to manipulate the data/array.
 *
 * Items of list will get their properties as objects where
 *    firstItem, secondItem and thirdItem: are the elements that will be rendered to same row,
 *    itemOnClick: the function which will handle onClick events of an indexed row and
 *
 * @param {IOwnProps}
 *  items?: Array<{
 *    firstItem?: React.JSX.Element | string | number;
 *    secondItem?: React.JSX.Element | string | number;
 *    thirdItem?: React.JSX.Element | string | number;
 *    itemOnClick?: () => void;
 *  }>;
 *  button?: React.JSX.Element;
 *  buttonOnClick?: () => void;
 *
 * @returns {React.JSX.Element}
 */
const ItemList = ({ headers, items, separator, button, buttonOnClick }: IOwnProps): React.JSX.Element => (
  <React.Fragment>
    {headers ? (
      <Container style={headers?.style}>
        <Item xs={11}>
          <Container>
            <Item xs={4}>{headers?.firstHeader ?? <div>&nbsp;</div>}</Item>
            <Item xs={4}>{headers?.secondHeader ?? <div>&nbsp;</div>}</Item>
            <Item xs={4}>{headers?.thirdHeader ?? <div>&nbsp;</div>}</Item>
          </Container>
        </Item>
      </Container>
    ) : null}
    {items ? (
      <React.Fragment>
        {items.map(
          (
            item: {
              firstItem?: React.JSX.Element | string | number;
              secondItem?: React.JSX.Element | string | number;
              thirdItem?: React.JSX.Element | string | number;
              itemOnClick?: () => void;
              style?: TAnyObject;
            },
            index: number,
            arr: typeof items,
          ) => (
            <div key={index} style={{ paddingTop: headers ? '1rem' : '0rem' }}>
              <Container style={item.style}>
                <Item xs={11}>
                  <Container>
                    <Item key={'firstItem'} xs={4}>
                      {item.firstItem ? item.firstItem : <div>&nbsp;</div>}
                    </Item>
                    <Item xs={4} key={'secondItem'}>
                      {item.secondItem ? item.secondItem : <div>&nbsp;</div>}
                    </Item>
                    <Item xs={4} key={'thirdItem'}>
                      {item.thirdItem ? item.thirdItem : <div>&nbsp;</div>}
                    </Item>
                  </Container>
                </Item>
                {item.itemOnClick ? (
                  <Item xs={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <ClearIconStyled onClick={item.itemOnClick} />
                  </Item>
                ) : null}
              </Container>
              {separator && arr.length > 1 && index < arr.length - 1 && (
                <Container style={separator.style}>
                  <Item>{separator.content}</Item>
                </Container>
              )}
            </div>
          ),
        )}
      </React.Fragment>
    ) : null}
    <StyledNewRowButton
      onClick={buttonOnClick}
      style={{ paddingTop: headers || (items && items.length > 0) ? '1rem' : '0rem' }}
    >
      {button}
    </StyledNewRowButton>
  </React.Fragment>
);

interface IHeaders {
  firstHeader?: React.JSX.Element | string | number;
  secondHeader?: React.JSX.Element | string | number;
  thirdHeader?: React.JSX.Element | string | number;
  style?: TAnyObject;
}

interface IItems {
  firstItem?: React.JSX.Element | string | number;
  secondItem?: React.JSX.Element | string | number;
  thirdItem?: React.JSX.Element | string | number;
  itemOnClick?: () => void;
  style?: TAnyObject;
}

interface ISeparator {
  content?: React.JSX.Element | string | number;
  style?: TAnyObject;
}

interface IOwnProps {
  headers?: IHeaders;
  items?: Array<IItems>;
  separator?: ISeparator;
  button?: React.JSX.Element;
  buttonOnClick?: () => void;
}

export default ItemList;
